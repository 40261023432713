import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { ListViewComponent } from "@syncfusion/ej2-react-lists";
import {
  DialogComponent,
  TooltipComponent,
} from "@syncfusion/ej2-react-popups";
import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import * as React from "react";
import { Fragment, PropsWithChildren } from "react";
import { Field, FormRenderProps } from "react-final-form";
import {
  getQueryAttribute,
  hideProgress,
  removeSessionStorage,
  showProgress,
} from "../../RAFComponents/helpers/AppHelper";
import {
  Guid,
  IsNotNullOrWhiteSpace,
  IsNullOrWhiteSpace,
  deepEqual,
  hexToRGBA,
  isNotEmptyArray,
  isNotNullAndUndefined,
  isNullOrUndefined,
} from "../../RAFComponents/helpers/utils";
import {
  RAFTaskTypeWithColor,
  StorageKey,
} from "../../constants/Common/Constants";
import { RAFCustomOperator } from "../RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { QueryAttributeJM, ValueJson } from "../models/Common/QueryAttributeJM";
import "./InputStyle.scss";
import ManageChoiceList from "./ManageChoiceList";
import RAFFieldLabel from "./RAFFieldLabel";
import { RAFFieldError, composeValidators } from "./RAFForm";
import {
  RAFDefaultFieldClassName,
  RAFDefaultFieldProps,
  RAFDropdownFieldProps,
  RAFFormContext,
  isRequired,
} from "./RFFUtils";
import DOMPurify from "dompurify";

const otherChoicelistArray = ["other", "others"];

const getDataFromChildren = (
  children,
  addEmpty: boolean,
  emptyString: string,
  attributeJM: QueryAttributeJM,
  valueJsonFilter: {
    Field: keyof ValueJson;
    Value: string;
    Operator: RAFCustomOperator.Equal | RAFCustomOperator.NotEqual;
  },
  sortByTitle: "ASC" | "DESC"
) => {
  let retVal: ValueJson[] = [];

  if (isNotNullAndUndefined(children) && children.length > 0) {
    if (isNotNullAndUndefined(addEmpty) && addEmpty === true) {
      retVal.push({
        Id: 0,
        Name: null,
        DisplayName: emptyString || "None",
        ColorCode: "#B3B6B7",
      });
    }
    React.Children.forEach(children, (child, i) => {
      //retVal.push({ ...child["props"], label: (child["props"]["children"] || child["props"]["label"]) });
      retVal.push({
        Id: i + 1,
        Name: child["props"]["value"],
        DisplayName: child["props"]["children"] || child["props"]["label"],
        ColorCode: child["props"]["colorCode"],
        IconCss: child["props"]["IconCss"] || child["props"]["Icon"],
      });
    });
  } else {
    if (isNotNullAndUndefined(addEmpty) && addEmpty === true) {
      retVal.push({
        Id: 0,
        Name: "",
        DisplayName: emptyString || "None",
        ColorCode: "transparent",
        IconCss: null,
      });
    }
    if (isNotNullAndUndefined(attributeJM)) {
      const attributeJMValueJson = attributeJM.ValueJson;
      if (isNotEmptyArray(attributeJMValueJson)) {
        const attributeJMValueJsonSorted = isNotNullAndUndefined(sortByTitle)
          ? attributeJMValueJson.sort((a, b) => {
              if (sortByTitle === "ASC") {
                return a.DisplayName.localeCompare(b.DisplayName);
              } else {
                return b.DisplayName.localeCompare(a.DisplayName);
              }
            })
          : attributeJMValueJson;
        if (isNotNullAndUndefined(valueJsonFilter)) {
          if (valueJsonFilter.Operator === RAFCustomOperator.Equal) {
            const filterValueJson = attributeJMValueJsonSorted.filter(
              (x) =>
                IsNotNullOrWhiteSpace(x[valueJsonFilter.Field]) &&
                IsNotNullOrWhiteSpace(valueJsonFilter.Value) &&
                x[valueJsonFilter.Field].toString().toLowerCase() ===
                  valueJsonFilter.Value.toLowerCase()
            );
            retVal.push(...filterValueJson);
          } else if (valueJsonFilter.Operator === RAFCustomOperator.NotEqual) {
            const filterValueJson = attributeJMValueJsonSorted.filter(
              (x) =>
                IsNotNullOrWhiteSpace(x[valueJsonFilter.Field]) &&
                IsNotNullOrWhiteSpace(valueJsonFilter.Value) &&
                x[valueJsonFilter.Field].toString().toLowerCase() !==
                  valueJsonFilter.Value.toLowerCase()
            );
            retVal.push(...filterValueJson);
          }
        } else {
          retVal.push(...attributeJMValueJsonSorted);
        }
      }
    }
  }
  return retVal;
};

interface IProps {
  moduleName?: string;
  addEmpty?: boolean;
  clearSelectionOnClick?: boolean;
  emptyString?: string;
  queryAttribute?: QueryAttributeJM;
  disableItems?: any[];
  hasChild?: boolean;
  displayDropdownType?:
    | "outlineDropdownBtn"
    | "iconDropdownBtn"
    | "customDropdownBtn"
    | "customDropdownBtnForMinCount"
    | "colorDropdown"
    | "Default";
  displayType?: "Dropdown" | "Default";
  description?: string;
  customDropdownBtnClassname?: string;
  colorDropdownRowClass?: string;
  btnWidth?: "FullWidth" | "default";
}

interface IState {
  showManageChoiceList: boolean;
  attributeJM: QueryAttributeJM;
  dropDownItem: ValueJson[];
}

class RAFDropdownCC<T> extends React.Component<
  PropsWithChildren<RAFDropdownFieldProps<T> & IProps>,
  IState
> {
  //static defaultProps: Partial<IProps>;
  static defaultProps = {
    ...RAFDefaultFieldProps,
    createOptionMode: "Default",
    allowAdd: false,
    displayType: "Default",
    hasChild: false,
  };

  _isMounted = false;

  private listViewComponent: ListViewComponent | null;

  private uitype = isNotNullAndUndefined(this.props.uitype)
    ? this.props.uitype
    : "default";
  //private allowAdd = isNotNullAndUndefined(this.props.allowAdd) && this.props.allowAdd === true && isNotNullAndUndefined(this.props.moduleName) ? true
  //    :
  //    isNotNullAndUndefined(this.props.moduleName) ? true : false;
  private textField = isNotNullAndUndefined(this.props.textField)
    ? this.props.textField
    : "DisplayName";
  private valueField = isNotNullAndUndefined(this.props.valueField)
    ? this.props.valueField
    : "Name";

  private fields = {
    text: "DisplayName",
    value: "Name",
    iconCss: "IconCss",
    itemCreated: (e) => {
      if (
        isNotNullAndUndefined(this.props.disableItems) &&
        this.props.disableItems.length > 0
      ) {
        this.props.disableItems.forEach((item) => {
          if (isNotNullAndUndefined(item) && item.value === e.curData.Name) {
            e.item.classList.add("e-disabled");
            e.item.classList.add("e-overlay");
          }
        });
      }
    },
  };
  private field = this.props.field.toString();
  private rafFormContextValue: FormRenderProps;
  private indexVal: string = Guid.newGuid();
  private dropDownListComponent: DropDownListComponent;
  private dropdownBtnComponent: DropDownButtonComponent;

  //private items = [] // = this.props.uitype === 'colorpicker' ? getDataFromChildren(null, this.props.required ? false : true, this.props.emptyString, this.state.attributeJM) : getDataFromChildren(React.Children.toArray(this.props.children), false, '', this.state.attributeJM);

  constructor(props) {
    super(props);
    if (isNotNullAndUndefined(this.textField)) {
      this.fields.text = this.textField;
    }
    if (isNotNullAndUndefined(this.valueField)) {
      this.fields.value = this.valueField;
    }
    this.state = {
      showManageChoiceList: false,
      attributeJM: isNotNullAndUndefined(this.props.queryAttribute)
        ? this.props.queryAttribute
        : {},
      dropDownItem: [],
    };
  }

  componentDidMount = () => {
    this._isMounted = true;
    this.fetchDropdowItems();
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    let addEmptyItem = isNotNullAndUndefined(this.props.addEmpty)
      ? this.props.addEmpty
      : this.props.required
      ? false
      : true;
    let dropDownItem =
      this.props.hasChild === true && this.uitype === "colorpicker"
        ? getDataFromChildren(
            React.Children.toArray(this.props.children),
            false,
            "",
            this.state.attributeJM,
            this.props.valueJsonFilter,
            this.props.sortByTitle
          )
        : this.uitype === "colorpicker"
        ? getDataFromChildren(
            null,
            addEmptyItem,
            this.props.emptyString,
            this.state.attributeJM,
            this.props.valueJsonFilter,
            this.props.sortByTitle
          )
        : getDataFromChildren(
            React.Children.toArray(this.props.children),
            false,
            "",
            this.state.attributeJM,
            this.props.valueJsonFilter,
            this.props.sortByTitle
          );

    if (!deepEqual(prevState.dropDownItem, dropDownItem)) {
      this.fetchDropdowItems();
    } else {
      if (!deepEqual(prevProps.disableItems, this.props.disableItems)) {
        if (isNotNullAndUndefined(this.dropDownListComponent)) {
          this.dropDownListComponent.refresh();
        }
      }
    }
  }

  fetchDropdowItems = () => {
    if (isNullOrUndefined(this.props.queryAttribute)) {
      getQueryAttribute(this.props.moduleName, this.field)
        .then((queryAttribute) => {
          if (this._isMounted) {
            this.updateStateValues(queryAttribute);
          }
        })
        .catch((error) => error);
    } else {
      if (this._isMounted) {
        this.updateStateValues(this.props.queryAttribute);
      }
    }
  };

  setClickEvents = (items1) => {
    const btnManageChoice: HTMLElement = document.getElementById(
      `btnManageChoice_${this.field}_${this.indexVal}`
    );
    if (isNotNullAndUndefined(btnManageChoice)) {
      btnManageChoice.onclick = () => {
        this.createModalToggle("customBtn_" + this.field);
      };
    }

    if (isNotNullAndUndefined(items1) && items1.length > 0) {
      for (var i = 0; i < items1.length; i++) {
        const objItem = items1[i];
        const objElement: HTMLElement = document.getElementById(
          `dropdown_item_${objItem.Name}_${this.field}_${this.indexVal}`
        );
        if (isNotNullAndUndefined(objElement)) {
          objElement.onclick = () => {
            this.onItemClicked(
              objItem.DisplayName,
              objItem.Name,
              "customBtn_" + this.field.replace(".", "_"),
              objItem.ColorCode
            );
          };
        }
      }
    }
  };

  createModalToggle = (divId) => {
    //let dropdownTypeInitialvalues = createInstance(AttributeChoiceList);
    //dropdownTypeInitialvalues['ChoiceList'] = choiceOptions;
    let customDropdownMenu = document.querySelectorAll("." + divId);
    if (isNotNullAndUndefined(customDropdownMenu)) {
      customDropdownMenu.forEach((item) => {
        item.classList.remove("e-popup-open");
        item.classList.add("e-popup-close");
      });
    }
    this.openManageChoiceListDialog();
  };

  refreshDropdown = () => {
    if (this._isMounted) {
      this.setState({ showManageChoiceList: false }, () => {
        removeSessionStorage(
          StorageKey.allAttribs_modulename + this.props.moduleName,
          true
        );
        removeSessionStorage(
          StorageKey.viewAttribs_modulename + this.props.moduleName,
          true
        );
        //if (isNullOrUndefined(this.props.queryAttribute)) {
        let progressDiv = showProgress("#rafdiv" + this.field);
        getQueryAttribute(this.props.moduleName, this.field)
          .then((queryAttribute) => {
            hideProgress(progressDiv);
            let addEmptyItem = isNotNullAndUndefined(this.props.addEmpty)
              ? this.props.addEmpty
              : this.props.required
              ? false
              : true;
            let dropDownItem =
              this.props.hasChild === true && this.uitype === "colorpicker"
                ? getDataFromChildren(
                    React.Children.toArray(this.props.children),
                    false,
                    "",
                    queryAttribute,
                    this.props.valueJsonFilter,
                    this.props.sortByTitle
                  )
                : this.uitype === "colorpicker"
                ? getDataFromChildren(
                    null,
                    addEmptyItem,
                    this.props.emptyString,
                    queryAttribute,
                    this.props.valueJsonFilter,
                    this.props.sortByTitle
                  )
                : getDataFromChildren(
                    React.Children.toArray(this.props.children),
                    false,
                    "",
                    queryAttribute,
                    this.props.valueJsonFilter,
                    this.props.sortByTitle
                  );
            if (
              isNotNullAndUndefined(this.rafFormContextValue) &&
              isNotNullAndUndefined(this.rafFormContextValue.form) &&
              isNotNullAndUndefined(this.rafFormContextValue.form.mutators)
            ) {
              let selectedDropDownItem =
                this.rafFormContextValue.values[this.field];
              if (
                isNullOrUndefined(
                  dropDownItem.find(
                    (x) => x.DisplayName === selectedDropDownItem
                  )
                )
              ) {
                this.rafFormContextValue.form.mutators.setValue(
                  this.field,
                  null
                );
              }
            }
            this.updateStateValues(queryAttribute);
          })
          .catch((error) => error);
        //}
      });
    }
  };

  updateStateValues = (attributeJM: QueryAttributeJM) => {
    if (this._isMounted) {
      let addEmptyItem = isNotNullAndUndefined(this.props.addEmpty)
        ? this.props.addEmpty
        : this.props.required
        ? false
        : true;

      let dropDownItem =
        this.props.hasChild === true && this.uitype === "colorpicker"
          ? getDataFromChildren(
              React.Children.toArray(this.props.children),
              false,
              "",
              attributeJM,
              this.props.valueJsonFilter,
              this.props.sortByTitle
            )
          : this.uitype === "colorpicker"
          ? getDataFromChildren(
              null,
              addEmptyItem,
              this.props.emptyString,
              attributeJM,
              this.props.valueJsonFilter,
              this.props.sortByTitle
            )
          : getDataFromChildren(
              React.Children.toArray(this.props.children),
              false,
              "",
              attributeJM,
              this.props.valueJsonFilter,
              this.props.sortByTitle
            );

      this.setState({ attributeJM, dropDownItem }, () => {
        if (this.props.disabled !== true) {
          setTimeout(() => {
            this.setClickEvents(dropDownItem);
            if (isNotNullAndUndefined(this.listViewComponent)) {
              this.listViewComponent.dataSource = dropDownItem as {
                [key: string]: Object;
              }[];
              this.listViewComponent.fields = {
                text: "DisplayName",
                tooltip: "DisplayName",
                id: "Id",
                iconCss: "IconCss",
              };
            }
          }, 100);
        }
      });
    }
  };

  setValueDelay = (inputValue) => {
    setTimeout(() => {
      if (this.dropDownListComponent) {
        this.dropDownListComponent.value = inputValue ?? null;
      }
    }, 100);
  };

  onNodeSelect = (e) => {
    let divId = "customBtn_" + this.props.field.toString();
    let customDropdownMenu = document.querySelectorAll("." + divId);
    if (isNotNullAndUndefined(customDropdownMenu)) {
      customDropdownMenu.forEach((item) => {
        item.classList.remove("e-popup-open");
        item.classList.add("e-popup-close");
      });
    }
    let customDropdownBtn = document.querySelector<HTMLElement>(
      ".customBtn_" + this.field
    );
    if (isNotNullAndUndefined(customDropdownBtn)) {
      if (isNullOrUndefined(this.props.mode)) {
        customDropdownBtn.style.background = e.colorCode;
      }
      //customDropdownBtn.style.color = '#fff';
      customDropdownBtn.style.paddingLeft = "15px";
      customDropdownBtn.style.paddingRight = "10px";
      customDropdownBtn.style.fontWeight = "400";
    }

    if (
      isNotNullAndUndefined(this.rafFormContextValue) &&
      isNotNullAndUndefined(this.rafFormContextValue.form) &&
      isNotNullAndUndefined(this.rafFormContextValue.form.mutators)
    ) {
      if (this.props.clearSelectionOnClick === true) {
        let currentfiledValue = this.rafFormContextValue.values[this.field];
        if (currentfiledValue === e.text) {
          e.text = null;
          e.text = null;
        }
      }
      /*value === '' ?
                          rafFormContextValue.form.mutators.setValue(field.toString(), null) :
                          rafFormContextValue.form.mutators.setValue(field.toString(), value);*/
      e.text === ""
        ? this.rafFormContextValue.form.mutators.setValue(this.field, null)
        : this.rafFormContextValue.form.mutators.setValue(this.field, e.text);
    }

    if (isNotNullAndUndefined(this.props.onChanged)) {
      this.props.onChanged(
        e.text !== null ? e.text : null,
        e.text !== null ? e.text : null
      );
    }

    if (isNotNullAndUndefined(this.dropdownBtnComponent)) {
      //dropdownBtnComponent.content = label;
      if (this.props.mode === "iconView") {
        this.dropdownBtnComponent.element.innerHTML = `<div><i class=${this.props.iconName} style="color: ${e.colorCode}"></i><span>${e.text}</span>
            </div><span class="e-btn-icon e-icons e-icon-right e-caret"></span>`;
      } else if (this.props.mode === "squareView") {
        this.dropdownBtnComponent.element.innerHTML = `<div><i class="fas fa-square" style="color: ${e.colorCode}"></i><span>${e.text}</span>
            </div><span class="e-btn-icon e-icons e-icon-right e-caret"></span>`;
      } else {
        this.dropdownBtnComponent.element.innerHTML = `<div><i class="fas fa-circle" style="color: ${e.colorCode}"></i><span>${e.text}</span>
            </div><span class="e-btn-icon e-icons e-icon-right e-caret"></span>`;
      }
    }
  };

  onItemClicked = (label, value, divId, colorCode) => {
    let customDropdownMenu = document.querySelectorAll("." + divId);
    if (isNotNullAndUndefined(customDropdownMenu)) {
      customDropdownMenu.forEach((item) => {
        item.classList.remove("e-popup-open");
        item.classList.add("e-popup-close");
      });
    }
    let customDropdownBtn = document.querySelector<HTMLElement>(
      ".customBtn_" + this.field
    );
    if (isNotNullAndUndefined(customDropdownBtn)) {
      if (isNullOrUndefined(this.props.mode)) {
        customDropdownBtn.style.background = colorCode;
      }
      //customDropdownBtn.style.color = '#fff';
      customDropdownBtn.style.paddingLeft = "15px";
      customDropdownBtn.style.paddingRight = "10px";
      customDropdownBtn.style.fontWeight = "400";
    }

    if (
      isNotNullAndUndefined(this.rafFormContextValue) &&
      isNotNullAndUndefined(this.rafFormContextValue.form) &&
      isNotNullAndUndefined(this.rafFormContextValue.form.mutators)
    ) {
      if (this.props.clearSelectionOnClick === true) {
        let currentfiledValue = this.rafFormContextValue.values[this.field];
        if (currentfiledValue === label) {
          label = null;
          value = null;
        }
      }
      /*value === '' ?
                          rafFormContextValue.form.mutators.setValue(field.toString(), null) :
                          rafFormContextValue.form.mutators.setValue(field.toString(), value);*/
      label === ""
        ? this.rafFormContextValue.form.mutators.setValue(this.field, null)
        : this.rafFormContextValue.form.mutators.setValue(this.field, label);
    }

    if (isNotNullAndUndefined(this.props.onChanged)) {
      this.props.onChanged(
        label !== null ? label : null,
        value !== null ? value : null
      );
    }

    if (isNotNullAndUndefined(this.dropdownBtnComponent)) {
      //dropdownBtnComponent.content = label;
      if (this.props.mode === "iconView") {
        this.dropdownBtnComponent.element.innerHTML = `<div><i class=${this.props.iconName} style="color: ${colorCode}"></i><span>${label}</span>
            </div><span class="e-btn-icon e-icons e-icon-right e-caret"></span>`;
      } else if (this.props.mode === "squareView") {
        this.dropdownBtnComponent.element.innerHTML = `<div><i class="fas fa-square" style="color: ${colorCode}"></i><span>${label}</span>
            </div><span class="e-btn-icon e-icons e-icon-right e-caret"></span>`;
      } else {
        this.dropdownBtnComponent.element.innerHTML = `<div><i class="fas fa-circle" style="color: ${colorCode}"></i><span>${label}</span>
            </div><span class="e-btn-icon e-icons e-icon-right e-caret"></span>`;
      }
    }
  };

  itemTemplate = (data: any): JSX.Element => {
    if (isNotNullAndUndefined(data)) {
      return (
        <div className="dropdown_item d-flex align-items-center m-2">
          <i className={data.IconCss}></i>
          <span className="ps-2 subtitle_1 regular">{data.DisplayName}</span>
        </div>
      );
    }
  };

  valueTemplate = (data: any): JSX.Element => {
    if (isNotNullAndUndefined(data)) {
      return (
        <div className="dropdown_value">
          <i className={data.IconCss}></i>
          <span className="ps-2 subtitle_1 regular">{data.DisplayName}</span>
        </div>
      );
    }
  };

  showManageChoiceListContent = () => {
    if (this.state.showManageChoiceList === true) {
      return (
        <ManageChoiceList
          allowAdd
          field={this.field}
          onSave={() => this.refreshDropdown()}
          onClose={() => this.closeCreateDialog()}
          attributeJM={this.state.attributeJM}
          //moduleName={moduleName}
        ></ManageChoiceList>
      );
    } else {
      return <div></div>;
    }
  };

  openManageChoiceListDialog = () => {
    if (this._isMounted) {
      this.setState({ showManageChoiceList: true });
    }
  };

  closeCreateDialog = () => {
    if (this._isMounted) {
      this.setState({ showManageChoiceList: false });
    }
  };

  footerTemplate = () => {
    return (
      <div
        className="row g-0 justify-content-between border-top"
        style={{ maxHeight: "32px", height: "32px", overflow: "hidden" }}
      >
        <div className="col-auto d-flex">
          <ButtonComponent
            type="button"
            iconCss="fas fa-plus"
            className="link-button custom-link-btn custom-button-sm"
            onClick={() => this.openManageChoiceListDialog()}
          >
            Add
          </ButtonComponent>
        </div>
      </div>
    );
  };

  dropdownClick = (btnClass, containerId) => {
    let btnDiv = document.querySelector("." + btnClass);

    let containerDiv = document.getElementById(containerId);

    if (isNotNullAndUndefined(btnDiv) && isNotNullAndUndefined(containerDiv)) {
      let btnDivWidth = btnDiv.clientWidth;

      containerDiv.style.width = btnDivWidth + "px";
    }
  };

  // bpTypeRowTemplate = (data: BusinessProcessTypeRow) => {
  //     if (isNotNullAndUndefined(data)) {
  //         const fieldValue = data.Title;
  //         const iconName: IconList = data.Icon as any;
  //         return (
  //             <div className="e-card">
  //                 <div className="workspace-img-div position-relative">
  //                     <div className="workspace-img w-100 h-100"></div>

  //                     {/* <img src={bpTypeImg} alt={data.Title} className="w-100 h-100" /> */}
  //                     {isEmptyOrNull(iconName) ? (
  //                         <div className="bp-type-avatar-outter">
  //                             <RAFIconImage
  //                                 iconCssClass={"fa fa-file-invoice"}
  //                                 //moduleavatar="bp-avatar hover-hide-td-child-div-flex"
  //                                 moduleavatar="avatar-text-square white-avatar"
  //                                 iconSize="80"
  //                                 fontSize="30"
  //                             ></RAFIconImage>
  //                         </div>
  //                     ) : (
  //                         <div className="bp-type-avatar-outter">
  //                             <span
  //                                 //className="bp-avatar avatar-text hover-hide-td-child-div-flex"
  //                                 className="avatar-text-square avatar-text white-avatar"
  //                             >
  //                                 <IconPickerItem icon={iconName} size={30} />
  //                             </span>
  //                         </div>
  //                     )}
  //                 </div>
  //                 <div className="e-card-content">
  //                     <div className="row g-3">
  //                         <div className="col-12">
  //                             <span className="title-value text-center ecllipseFirstLine">
  //                                 {data.Title}
  //                             </span>
  //                         </div>
  //                     </div>
  //                 </div>
  //                 <div className="p-3 pt-0">
  //                     <div className="row g-3">
  //                         <div className="col">
  //                             <ButtonComponent
  //                                 type="button"
  //                                 cssClass="e-custombutton e-info w-100"
  //                                 content="Add To Portal"
  //                             ></ButtonComponent>
  //                         </div>
  //                         <div className="col">
  //                             <ButtonComponent
  //                                 type="button"
  //                                 cssClass="e-custom-actionbutton w-100"
  //                                 content="Customize"
  //                             ></ButtonComponent>
  //                         </div>
  //                     </div>
  //                 </div>
  //                 {/* <div className="d-flex align-items-center w-100">
  //                 <div className="bp-template-item p-0 align-items-center">
  //                     {isEmptyOrNull(iconName) ?
  //                         <div>
  //                             <RAFIconImage iconCssClass={"fa fa-file-invoice"}
  //                                 //moduleavatar="bp-avatar hover-hide-td-child-div-flex"
  //                                 moduleavatar="form-library-avatar transparent-avatar"
  //                                 iconSize="30" fontSize="20"></RAFIconImage>
  //                         </div>
  //                         :
  //                         <div>
  //                             <span
  //                                 //className="bp-avatar avatar-text hover-hide-td-child-div-flex"
  //                                 className="form-library-avatar avatar-text transparent-avatar"
  //                                 style={{ width: "30px", height: "30px", minWidth: "30px", maxWidth: "30px", fontSize: "20px" }}>
  //                                 <IconPickerItem icon={iconName} size={20} />
  //                             </span>
  //                         </div>
  //                     }
  //                     <div className="px-2" style={{ width: "calc(100% - 30px)" }}>
  //                         <span className="bp-template-title ecllipseFirstLine text-wrap word-break-all">{fieldValue}</span>
  //                     </div>
  //                     <div className='detailsLeftCard-second-title h-26 line-seprator'>
  //                         <div className='row gx-2 hover-show-td-child-div-flex flex-nowrap align-items-center'>
  //                             <div className='col-auto detailsLeftCard-second-title-col'>
  //                                 <div className='detailsLeftCard-second-title-item'>
  //                                     <ButtonComponent type="button" className="e-custom-actionbutton unset-custom-button-md link-button e-small m-0">Edit</ButtonComponent>
  //                                 </div>
  //                             </div>
  //                             <div className='col-auto detailsLeftCard-second-title-col'>
  //                                 <div className='detailsLeftCard-second-title-item'>
  //                                     <ButtonComponent type="button" className="e-custombutton unset-custom-button-md link-button e-small m-0">Settings</ButtonComponent>
  //                                 </div>
  //                             </div>
  //                         </div>
  //                     </div>
  //                 </div>
  //             </div> */}
  //             </div>
  //         );
  //     }
  // };

  nodeTemplate = (data) => {
    let mode = this.props.mode;
    if (isNotNullAndUndefined(data)) {
      const item = data;
      let backgroundColor;
      let color;
      let iconColor;
      let iconName;
      if (isNotNullAndUndefined(mode)) {
        backgroundColor = "transparent";
        color = "block";
        iconColor = item.ColorCode;
        if (mode === "squareView") {
          iconName = "fas fa-square";
        } else if (mode === "iconView") {
          iconName = this.props.iconName;
        } else if (mode === "discView") {
          iconName = "fas fa-circle";
        }
      } else {
        backgroundColor = item.ColorCode;
        color = "white";
        iconColor = item.ColorCode;
      }

      return (
        <div
          id={`dropdown_item_${item.Name}_${this.props.field.toString()}_${
            this.indexVal
          }`}
          className="dropdown_item px-3"
          style={{
            background: backgroundColor,
            color:
              isNotNullAndUndefined(color) &&
              item.ColorCode !== "transparent" &&
              item.ColorCode !== ""
                ? color
                : "black",
          }}
          onClick={() =>
            this.onItemClicked(
              item.DisplayName,
              item.Name,
              "customBtn_" + this.props.field.toString(),
              item.ColorCode
            )
          }
        >
          {isNotNullAndUndefined(mode) ? (
            <i
              className={`${iconName} me-2`}
              style={{
                color: iconColor,
              }}
            ></i>
          ) : (
            ""
          )}
          <span className="text-truncate">{item.DisplayName}</span>
          {/* {inputValue ===
                            item.DisplayName ? (
                            <span className="fas fa-check"></span>
                        ) : null} */}
        </div>
      );
    }
  };

  getDisplayInputValue = (value) => {
    let items = this.state.dropDownItem;
    let displayValue = value;
    if (IsNotNullOrWhiteSpace(value)) {
      if (isNotEmptyArray(items)) {
        let itemExist = items.find(
          (x) => x.DisplayName === value || x.Name === value
        );
        if (
          IsNotNullOrWhiteSpace(this.isOtherItemExist()) &&
          isNullOrUndefined(itemExist)
        ) {
          displayValue = this.isOtherItemExist();
        } else {
          displayValue = value;
        }
      }
    }
    return displayValue;
  };

  isOtherItemExist() {
    let itemValue: string = null;
    const { dropDownItem } = this.state;
    if (isNotEmptyArray(this.state.dropDownItem)) {
      dropDownItem.forEach((x) => {
        if (
          IsNotNullOrWhiteSpace(x.Name) &&
          otherChoicelistArray.includes(x.Name.toString().toLowerCase())
        ) {
          itemValue = x.Name;
        }
        if (
          IsNotNullOrWhiteSpace(x.DisplayName) &&
          otherChoicelistArray.includes(x.DisplayName.toString().toLowerCase())
        ) {
          itemValue = x.DisplayName;
        }
      });
    }

    return itemValue;
  }

  onChangeTextBoxInputValue = (e, input) => {
    if (e.isInteracted) {
      let eValue = IsNotNullOrWhiteSpace(e.value)
        ? e.value
        : this.isOtherItemExist();
      input.onChange(eValue);
      if (isNotNullAndUndefined(this.props.onChanged)) {
        this.props.onChanged(eValue);
      }
    }
  };

  render() {
    let {
      field,
      initialValue,
      label,
      required,
      showLabel,
      width,
      height,
      validate,
      disabled,
      onChanged,
      validators,
      mode,
      iconName,
      emptyString,
      showClearButton,
      allowFiltering,
      createOptionMode,
      allowAdd,
      description,
      descriptionAsLabel,
      placeholder,
      titleLocation,
    } = this.props;

    let labelClassName = isNotNullAndUndefined(this.props.labelClassName)
      ? this.props.labelClassName
      : "";
    let rowClassName = isNotNullAndUndefined(this.props.rowClassName)
      ? `${this.props.rowClassName} row`
      : RAFDefaultFieldClassName.rowClassName;
    let inputFieldClassName = isNotNullAndUndefined(
      this.props.inputFieldClassName
    )
      ? this.props.inputFieldClassName
      : "col-12";
    if (titleLocation === "Right") {
      rowClassName = `${rowClassName} flex-nowrap gx-2`;
      labelClassName = "col-auto order-last";
      inputFieldClassName = "col";
    } else if (titleLocation === "Left") {
      rowClassName = `${rowClassName} flex-nowrap gx-2`;
      labelClassName = "col-3";
      inputFieldClassName = "col";
    } else if (titleLocation === "Bottom") {
      labelClassName = "order-last";
    }
    let items = this.state.dropDownItem;
    let { showManageChoiceList } = this.state;
    let displayDropdownType = isNotNullAndUndefined(
      this.props.displayDropdownType
    )
      ? this.props.displayDropdownType
      : null;
    if (isNullOrUndefined(displayDropdownType)) {
      if (isNullOrUndefined(items) && items.length < 5) {
        displayDropdownType = "customDropdownBtn";
      } else {
        displayDropdownType = "colorDropdown";
      }
    }
    //let defaultValue = isNotNullAndUndefined(items) && isNotNullAndUndefined(initialValue) && items.length > 0 ? items.find(x => isNotNullAndUndefined(x[this.fields.text]) && x[this.fields.text] === initialValue) : null;
    return (
      <Fragment>
        <RAFFormContext.Consumer>
          {(rafFormContextValue) => {
            this.rafFormContextValue = rafFormContextValue;
            return (
              <div
                className={
                  isNotNullAndUndefined(this.props.formGroupClassName)
                    ? this.props.formGroupClassName + " form-group"
                    : "form-group"
                }
              >
                <div className={rowClassName} id={"rafdiv" + field.toString()}>
                  {showLabel && showLabel === true && (
                    <RAFFieldLabel
                      field={field}
                      label={label}
                      required={required}
                      labelClassName={labelClassName}
                      description={description}
                      descriptionAsLabel={descriptionAsLabel}
                    ></RAFFieldLabel>
                  )}

                  <div className={inputFieldClassName}>
                    <Field
                      name={field.toString()}
                      {...(initialValue ? { initialValue: initialValue } : {})}
                      //validate={validate === true ? (required && isRequired) : null}
                      {...(validators
                        ? {
                            validate:
                              validate === true
                                ? composeValidators(
                                    required === true ? isRequired : null,
                                    ...validators
                                  )
                                : null,
                          }
                        : {
                            validate:
                              validate === true
                                ? composeValidators(
                                    required === true ? isRequired : null
                                  )
                                : null,
                          })}
                      allowNull
                      parse={(value) => (value === "" ? null : value)}
                    >
                      {({ input, meta }) => {
                        const inputValue = this.getDisplayInputValue(
                          input.value
                        );
                        this.setValueDelay(inputValue);

                        return (
                          <div>
                            {isNotEmptyArray(items) &&
                            this.uitype === "colorpicker" ? (
                              this.props.displayType === "Default" ? (
                                isNotNullAndUndefined(mode) ? (
                                  <div
                                    className={`${
                                      isNotNullAndUndefined(
                                        this.props.colorDropdownRowClass
                                      )
                                        ? this.props.colorDropdownRowClass
                                        : ""
                                    }row g-2 align-items-center`}
                                  >
                                    {displayDropdownType ===
                                      "colorDropdown" && (
                                      <Fragment>
                                        <div className="col">
                                          {/* <div
                                                                                                    className={"customDropdownMenu"}
                                                                                                    id={
                                                                                                        "drodownBtnComponent" +
                                                                                                        field.toString() +
                                                                                                        "_" +
                                                                                                        this.indexVal
                                                                                                    }
                                                                                                >
                                                                                                    {items.map((item, index1) => {
                                                                                                        return (
                                                                                                            <div
                                                                                                                key={`${item.Name}${index1}`}
                                                                                                                id={`dropdown_item_${item.Name
                                                                                                                    }_${field.toString()}_${this.indexVal
                                                                                                                    }`}
                                                                                                                className={
                                                                                                                    "dropdown_item colorIcon"
                                                                                                                }
                                                                                                            //onClick={() => this.onItemClicked(item.DisplayName, item.Name, 'customBtn_' + field.toString().replace(".", "_"), item.ColorCode)}
                                                                                                            >
                                                                                                                {mode === "squareView" ? (
                                                                                                                    <i
                                                                                                                        className="fas fa-square"
                                                                                                                        style={{
                                                                                                                            color:
                                                                                                                                item.ColorCode,
                                                                                                                        }}
                                                                                                                    ></i>
                                                                                                                ) : mode ===
                                                                                                                    "iconView" ? (
                                                                                                                    <i
                                                                                                                        className={iconName}
                                                                                                                        style={{
                                                                                                                            color:
                                                                                                                                item.ColorCode,
                                                                                                                        }}
                                                                                                                    ></i>
                                                                                                                ) : (
                                                                                                                    <i
                                                                                                                        className="fas fa-circle"
                                                                                                                        style={{
                                                                                                                            color:
                                                                                                                                item.ColorCode,
                                                                                                                        }}
                                                                                                                    ></i>
                                                                                                                )}
                                                                                                                <span className="w-100 text-truncate">
                                                                                                                    {item.DisplayName}
                                                                                                                </span>
                                                                                                                {inputValue ===
                                                                                                                    item.DisplayName ? (
                                                                                                                    <span className="fas fa-check"></span>
                                                                                                                ) : null}
                                                                                                            </div>
                                                                                                        );
                                                                                                    })}
                                                                                                    {createOptionMode ===
                                                                                                        "Footer" &&
                                                                                                        allowAdd === true && (
                                                                                                            <div className="dropdown_item">
                                                                                                                <ButtonComponent
                                                                                                                    id={`btnManageChoice_${field.toString()}_${this.indexVal
                                                                                                                        }`}
                                                                                                                    type="button"
                                                                                                                    iconCss="fas fa-plus"
                                                                                                                    className="custom-link-btn primary-custom-button unset-custom-button-md w-100"
                                                                                                                //onClick={() => this.openManageChoiceListDialog()}
                                                                                                                >
                                                                                                                    Add
                                                                                                                </ButtonComponent>
                                                                                                            </div>
                                                                                                        )}
                                                                                                </div> */}
                                          <ListViewComponent
                                            id={`drodownBtnComponent${field.toString()}_${
                                              this.indexVal
                                            }`}
                                            ref={(g) =>
                                              (this.listViewComponent = g)
                                            }
                                            template={this.nodeTemplate}
                                            //select={this.onNodeSelect}
                                            // dataSource={items}
                                            //fields={{ text: "DisplayName", tooltip: "DisplayName", id: "UID" }}

                                            // fields={fields}
                                            // showCheckBox={true}
                                          />
                                          <div className="d-flex">
                                            <DropDownButtonComponent
                                              id={`ddb_${field.toString()}`}
                                              ref={(d) =>
                                                (this.dropdownBtnComponent = d)
                                              }
                                              target={
                                                "#drodownBtnComponent" +
                                                field.toString() +
                                                "_" +
                                                this.indexVal
                                              }
                                              style={{ fontWeight: 400 }}
                                              cssClass={`customDropdownBtn customBtn_${field.toString()}_${mode}${
                                                isNotNullAndUndefined(
                                                  this.props
                                                    .customDropdownBtnClassname
                                                )
                                                  ? ` ${this.props.customDropdownBtnClassname}`
                                                  : " custom-button-lg"
                                              }`}
                                              // cssClass={`customDropdownBtn customBtn_${field.toString()}_${mode}
                                              // ${allowAdd === true ? " allowAdd" : ""}
                                              // ${isNotNullAndUndefined(this.props.customDropdownBtnClassname) ? ` ${this.props.customDropdownBtnClassname}` : " custom-button-lg"}`}
                                              // // cssClass={
                                              //   this.props
                                              //     .customDropdownBtnClassname
                                              //     ? this.props
                                              //       .customDropdownBtnClassname +
                                              //     " custom-button-lg customDropdownBtn customBtn_" +
                                              //     field.toString()
                                              //     : "custom-button-lg customDropdownBtn customBtn_" +
                                              //     field.toString()
                                              // }
                                              onClick={() =>
                                                this.dropdownClick(
                                                  "customBtn_" +
                                                    field.toString(),
                                                  "drodownBtnComponent" +
                                                    field.toString() +
                                                    "_" +
                                                    this.indexVal
                                                )
                                              }
                                            >
                                              {IsNullOrWhiteSpace(
                                                inputValue
                                              ) ? (
                                                isNotNullAndUndefined(
                                                  emptyString
                                                ) ? (
                                                  emptyString
                                                ) : (
                                                  <div>None</div>
                                                )
                                              ) : (
                                                <div className="d-flex align-items-center">
                                                  {mode === "squareView" ? (
                                                    <i
                                                      className="fas fa-square mt-1"
                                                      style={{
                                                        color:
                                                          items &&
                                                          items.find(
                                                            (x) =>
                                                              x.DisplayName ===
                                                              inputValue
                                                          ) &&
                                                          items.find(
                                                            (x) =>
                                                              x.DisplayName ===
                                                              inputValue
                                                          ).ColorCode,
                                                      }}
                                                    ></i>
                                                  ) : mode === "iconView" ? (
                                                    <i
                                                      className={`${iconName} mt-1`}
                                                      style={{
                                                        color:
                                                          items &&
                                                          items.find(
                                                            (x) =>
                                                              x.DisplayName ===
                                                              inputValue
                                                          ) &&
                                                          items.find(
                                                            (x) =>
                                                              x.DisplayName ===
                                                              inputValue
                                                          ).ColorCode,
                                                      }}
                                                    ></i>
                                                  ) : (
                                                    <i
                                                      className="fas fa-circle"
                                                      style={{
                                                        color:
                                                          items &&
                                                          items.find(
                                                            (x) =>
                                                              x.DisplayName ===
                                                              inputValue
                                                          ) &&
                                                          items.find(
                                                            (x) =>
                                                              x.DisplayName ===
                                                              inputValue
                                                          ).ColorCode,
                                                      }}
                                                    ></i>
                                                  )}
                                                  <span className="ecllipseFirstLine pe-3 word-break-all">
                                                    {inputValue}
                                                  </span>
                                                </div>
                                              )}
                                            </DropDownButtonComponent>
                                            {allowAdd === true && (
                                              <ButtonComponent
                                                id={`btnManageChoice_${field.toString()}_${
                                                  this.indexVal
                                                }`}
                                                type="button"
                                                iconCss="fas fa-plus"
                                                cssClass="btn_brand_secondary outline custom-button-lg ms-2"
                                                onClick={() =>
                                                  this.openManageChoiceListDialog()
                                                }
                                              ></ButtonComponent>
                                            )}
                                          </div>
                                          {/*<div className="customDropdownMenu" id={"drodownBtnComponent" + field.toString()}>
                                                {items.map(item => {
                                                    return <div key={item.Name} id={`dropdown_item_${item.Name}`} className="dropdown_item" style={{ background: item.ColorCode, color: isNotNullAndUndefined(item.ColorCode) ? "white" : "black" }} onClick={() => onItemClicked(item.DisplayName, item.Name, 'customBtn_' + field.toString(), item.ColorCode)}><span>{item.DisplayName}</span>{inputValue === item.Name ? <span className="e-icon e-selection"></span> : null}</div>
                                                })}
                                            </div><DropDownButtonComponent ref={d => dropdownBtnComponent = d} target={"#drodownBtnComponent" + field.toString()} style={{ color: isNotNullAndUndefined(items && items.find(x => x.Name === inputValue) && items.find(x => x.Name === inputValue).ColorCode) ? "white" : "black", background: items && items.find(x => x.Name === inputValue) && items.find(x => x.Name === inputValue).ColorCode, fontWeight: 400 }} cssClass={'customDropdownBtn customBtn_' + field.toString()}>{IsNullOrWhiteSpace(inputValue) ? '\u00a0' : getDisplayNamebyName(inputValue)}</DropDownButtonComponent>*/}
                                        </div>
                                        {createOptionMode === "Default" &&
                                          allowAdd === true && (
                                            <div className="col-auto">
                                              <ButtonComponent
                                                id={`btnManageChoice_${field.toString()}_${
                                                  this.indexVal
                                                }`}
                                                type="button"
                                                cssClass="btn_brand_secondary outline custom-button-lg ms-2"
                                                // cssClass="e-flat icon-only default filterIcon"
                                                iconCss="fas fa-plus"
                                                //onClick={() => CreateModalToggle()}
                                              ></ButtonComponent>
                                            </div>
                                          )}
                                      </Fragment>
                                    )}
                                    {displayDropdownType ===
                                      "customDropdownBtn" && (
                                      <Fragment>
                                        <div className="col">
                                          <div
                                            className={`${
                                              isNotNullAndUndefined(
                                                this.props.colorDropdownRowClass
                                              )
                                                ? `${this.props.colorDropdownRowClass} `
                                                : ""
                                            }row g-2 flex-wrap`}
                                          >
                                            {items.map((item, index1) => {
                                              return (
                                                <div
                                                  key={`${item.Name}${index1}`}
                                                  className="col-auto"
                                                >
                                                  <TooltipComponent
                                                    content={item.DisplayName}
                                                    position="BottomCenter"
                                                  >
                                                    <div
                                                      id={`dropdown_item_${
                                                        item.Name
                                                      }_${field.toString()}_${
                                                        this.indexVal
                                                      }`}
                                                      className={
                                                        inputValue ===
                                                        item.DisplayName
                                                          ? "custom-dropdown-btn active"
                                                          : "col custom-dropdown-btn"
                                                      }
                                                      //onClick={() => this.onItemClicked(item.DisplayName, item.Name, 'customBtn_' + field.toString(), item.ColorCode)}
                                                    >
                                                      {mode === "squareView" ? (
                                                        <i
                                                          className="fas fa-square"
                                                          style={{
                                                            color:
                                                              item.ColorCode,
                                                          }}
                                                        ></i>
                                                      ) : mode ===
                                                        "iconView" ? (
                                                        <i
                                                          className={iconName}
                                                          style={{
                                                            color:
                                                              item.ColorCode,
                                                          }}
                                                        ></i>
                                                      ) : (
                                                        <i
                                                          className="fas fa-circle"
                                                          style={{
                                                            color:
                                                              item.ColorCode,
                                                          }}
                                                        ></i>
                                                      )}
                                                      <span className="w-100 ps-2 secondary-header-text-dark">
                                                        {item.DisplayName}
                                                      </span>
                                                      {/* {inputValue === item.DisplayName ?
                                                          <span className="e-icon e-selection"></span> : null} */}
                                                    </div>
                                                  </TooltipComponent>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                        {allowAdd === true && (
                                          <div className="col-auto">
                                            <ButtonComponent
                                              id={`btnManageChoice_${field.toString()}_${
                                                this.indexVal
                                              }`}
                                              type="button"
                                              cssClass="btn_brand_secondary outline custom-button-lg ms-2"
                                              // cssClass="e-flat icon-only default filterIcon"
                                              iconCss="fas fa-plus"
                                              //onClick={() => CreateModalToggle()}
                                            ></ButtonComponent>
                                          </div>
                                        )}
                                      </Fragment>
                                    )}
                                  </div>
                                ) : (
                                  <div className="row g-2 align-items-center">
                                    {(displayDropdownType === "colorDropdown" ||
                                      (displayDropdownType ===
                                        "customDropdownBtnForMinCount" &&
                                        items.length > 4)) && (
                                      <Fragment>
                                        <div className="col">
                                          <ListViewComponent
                                            id={
                                              "drodownBtnComponent_" +
                                              field
                                                .toString()
                                                .replace(".", "_") +
                                              "_" +
                                              this.indexVal
                                            }
                                            ref={(g) =>
                                              (this.listViewComponent = g)
                                            }
                                            template={this.nodeTemplate}
                                            // dataSource={items}
                                            //fields={{ text: "DisplayName", tooltip: "DisplayName", id: "UID" }}
                                            //select={this.onNodeSelect}
                                            // fields={fields}
                                            // showCheckBox={true}
                                          />

                                          <div className="d-flex">
                                            <DropDownButtonComponent
                                              id={`ddb_${field.toString()}`}
                                              target={
                                                "#drodownBtnComponent_" +
                                                field
                                                  .toString()
                                                  .replace(".", "_") +
                                                "_" +
                                                this.indexVal
                                              }
                                              onClick={() =>
                                                this.dropdownClick(
                                                  "customBtn_" +
                                                    field.toString(),
                                                  "drodownBtnComponent_" +
                                                    field
                                                      .toString()
                                                      .replace(".", "_") +
                                                    "_" +
                                                    this.indexVal
                                                )
                                              }
                                              style={{
                                                color:
                                                  isNotNullAndUndefined(
                                                    items &&
                                                      items.find(
                                                        (x) =>
                                                          x.DisplayName ===
                                                          inputValue
                                                      ) &&
                                                      items.find(
                                                        (x) =>
                                                          x.DisplayName ===
                                                          inputValue
                                                      ).ColorCode
                                                  ) &&
                                                  items.find(
                                                    (x) =>
                                                      x.DisplayName ===
                                                      inputValue
                                                  ).ColorCode !==
                                                    "transparent" &&
                                                  items.find(
                                                    (x) =>
                                                      x.DisplayName ===
                                                      inputValue
                                                  ).ColorCode !== ""
                                                    ? "white"
                                                    : "black",
                                                background:
                                                  items &&
                                                  items.find(
                                                    (x) =>
                                                      x.DisplayName ===
                                                      inputValue
                                                  ) &&
                                                  items.find(
                                                    (x) =>
                                                      x.DisplayName ===
                                                      inputValue
                                                  ).ColorCode,
                                                borderColor:
                                                  isNotNullAndUndefined(
                                                    items &&
                                                      items.find(
                                                        (x) =>
                                                          x.DisplayName ===
                                                          inputValue
                                                      ) &&
                                                      items.find(
                                                        (x) =>
                                                          x.DisplayName ===
                                                          inputValue
                                                      ).ColorCode
                                                  ) &&
                                                  items.find(
                                                    (x) =>
                                                      x.DisplayName ===
                                                      inputValue
                                                  ).ColorCode !==
                                                    "transparent" &&
                                                  items.find(
                                                    (x) =>
                                                      x.DisplayName ===
                                                      inputValue
                                                  ).ColorCode !== ""
                                                    ? "white"
                                                    : "rgba(0, 0, 0, 0.23)",
                                                fontWeight: 400,
                                                height:
                                                  isNotNullAndUndefined(
                                                    height
                                                  ) && height,
                                              }}
                                              // cssClass={
                                              //   this.props
                                              //     .customDropdownBtnClassname
                                              //     ? this.props
                                              //       .customDropdownBtnClassname +
                                              //     "  custom-button-lg customDropdownBtn customBtn_" +
                                              //     field
                                              //       .toString()
                                              //       .replace(".", "_")
                                              //     : "custom-button-lg customDropdownBtn customBtn_" +
                                              //     field
                                              //       .toString()
                                              //       .replace(".", "_")
                                              // }
                                              cssClass={`customDropdownBtn customBtn_${field
                                                .toString()
                                                .replace(".", "_")}${
                                                allowAdd === true
                                                  ? " allowAdd"
                                                  : ""
                                              }${
                                                isNotNullAndUndefined(
                                                  this.props
                                                    .customDropdownBtnClassname
                                                )
                                                  ? ` ${this.props.customDropdownBtnClassname}`
                                                  : " custom-button-lg"
                                              }`}
                                            >
                                              {IsNullOrWhiteSpace(
                                                inputValue
                                              ) ? (
                                                isNotNullAndUndefined(
                                                  emptyString
                                                ) ? (
                                                  emptyString
                                                ) : isNotNullAndUndefined(
                                                    required
                                                  ) && required ? null : (
                                                  "None"
                                                )
                                              ) : (
                                                <span className="ecllipseFirstLine pe-3 word-break-all">
                                                  {inputValue}
                                                </span>
                                              )}
                                            </DropDownButtonComponent>
                                            {allowAdd === true && (
                                              <ButtonComponent
                                                id={`btnManageChoice_${field.toString()}_${
                                                  this.indexVal
                                                }`}
                                                type="button"
                                                iconCss="fas fa-plus"
                                                cssClass="btn_brand_secondary outline custom-button-lg ms-2"
                                                // className="primary-custom-button dropdownAddBtn custom-button-lg"
                                                onClick={() =>
                                                  this.openManageChoiceListDialog()
                                                }
                                              ></ButtonComponent>
                                            )}
                                          </div>
                                          {/* <div
                                                                                                    className="customDropdownMenu"
                                                                                                    id={
                                                                                                        "drodownBtnComponent_" +
                                                                                                        field
                                                                                                            .toString()
                                                                                                            .replace(".", "_") +
                                                                                                        "_" +
                                                                                                        this.indexVal
                                                                                                    }
                                                                                                >

                                                                                                    {items.map((item, index1) => {
                                                                                                        return (
                                                                                                            <div
                                                                                                                key={`${item.Name}${index1}`}
                                                                                                                id={`dropdown_item_${item.Name
                                                                                                                    }_${field.toString()}_${this.indexVal
                                                                                                                    }`}
                                                                                                                className="dropdown_item"
                                                                                                                style={{
                                                                                                                    background:
                                                                                                                        item.ColorCode,
                                                                                                                    color:
                                                                                                                        isNotNullAndUndefined(
                                                                                                                            item.ColorCode
                                                                                                                        ) &&
                                                                                                                            item.ColorCode !==
                                                                                                                            "transparent" &&
                                                                                                                            item.ColorCode !== ""
                                                                                                                            ? "white"
                                                                                                                            : "black",
                                                                                                                }}
                                                                                                            //onClick={() => this.onItemClicked(item.DisplayName, item.Name, 'customBtn_' + field.toString(), item.ColorCode)}
                                                                                                            >
                                                                                                                <span className="text-truncate">
                                                                                                                    {item.DisplayName}
                                                                                                                </span>
                                                                                                                {inputValue ===
                                                                                                                    item.DisplayName ? (
                                                                                                                    <span className="fas fa-check"></span>
                                                                                                                ) : null}
                                                                                                            </div>
                                                                                                        );
                                                                                                    })}
                                                                                                    {createOptionMode ===
                                                                                                        "Footer" &&
                                                                                                        allowAdd === true && (
                                                                                                            <div className="dropdown_item">
                                                                                                                <ButtonComponent
                                                                                                                    id={`btnManageChoice_${field.toString()}_${this.indexVal
                                                                                                                        }`}
                                                                                                                    type="button"
                                                                                                                    iconCss="fas fa-plus"
                                                                                                                    className="custom-link-btn primary-custom-button unset-custom-button-md w-100"
                                                                                                                //onClick={() => this.openManageChoiceListDialog()}
                                                                                                                >
                                                                                                                    Add
                                                                                                                </ButtonComponent>
                                                                                                            </div>
                                                                                                        )}
                                                                                                </div> */}
                                          {/*<div className="customDropdownMenu" id={"drodownBtnComponent" + field.toString()}>
                                                {items.map(item => {
                                                    return <div key={item.Name} id={`dropdown_item_${item.Name}`} className="dropdown_item" style={{ background: item.ColorCode, color: isNotNullAndUndefined(item.ColorCode) ? "white" : "black" }} onClick={() => onItemClicked(item.DisplayName, item.Name, 'customBtn_' + field.toString(), item.ColorCode)}><span>{item.DisplayName}</span>{inputValue === item.Name ? <span className="e-icon e-selection"></span> : null}</div>
                                                })}
                                            </div><DropDownButtonComponent ref={d => dropdownBtnComponent = d} target={"#drodownBtnComponent" + field.toString()} style={{ color: isNotNullAndUndefined(items && items.find(x => x.Name === inputValue) && items.find(x => x.Name === inputValue).ColorCode) ? "white" : "black", background: items && items.find(x => x.Name === inputValue) && items.find(x => x.Name === inputValue).ColorCode, fontWeight: 400 }} cssClass={'customDropdownBtn customBtn_' + field.toString()}>{IsNullOrWhiteSpace(inputValue) ? '\u00a0' : getDisplayNamebyName(inputValue)}</DropDownButtonComponent>*/}
                                        </div>
                                        <div className="hidden">
                                          {isNotNullAndUndefined(
                                            createOptionMode
                                          ) &&
                                            isNotNullAndUndefined(allowAdd) &&
                                            createOptionMode === "Default" &&
                                            allowAdd === true && (
                                              <div className="col-auto">
                                                <ButtonComponent
                                                  id={`btnManageChoice_${field.toString()}_${
                                                    this.indexVal
                                                  }`}
                                                  type="button"
                                                  cssClass="e-flat icon-only default filterIcon"
                                                  iconCss="fas fa-plus"
                                                  //onClick={() => CreateModalToggle()}
                                                ></ButtonComponent>
                                              </div>
                                            )}
                                        </div>
                                      </Fragment>
                                    )}
                                    {(displayDropdownType ===
                                      "customDropdownBtn" ||
                                      (displayDropdownType ===
                                        "customDropdownBtnForMinCount" &&
                                        items.length <= 4)) && (
                                      <Fragment>
                                        <div
                                          className={
                                            this.props.btnWidth === "FullWidth"
                                              ? "col"
                                              : "col-auto"
                                          }
                                        >
                                          <div className="row g-2 flex-wrap">
                                            {items.map((item, index1) => {
                                              return (
                                                <div
                                                  className={
                                                    this.props.btnWidth ===
                                                    "FullWidth"
                                                      ? "col"
                                                      : "col-auto"
                                                  }
                                                  key={`${item.Name}${index1}`}
                                                >
                                                  <div
                                                    id={`dropdown_item_${
                                                      item.Name
                                                    }_${field.toString()}_${
                                                      this.indexVal
                                                    }`}
                                                    className={
                                                      inputValue ===
                                                      item.DisplayName
                                                        ? "custom-dropdown-btn active"
                                                        : "col custom-dropdown-btn"
                                                    }
                                                    style={{
                                                      background:
                                                        item.ColorCode,
                                                      color:
                                                        isNotNullAndUndefined(
                                                          item.ColorCode
                                                        ) &&
                                                        item.ColorCode !==
                                                          "transparent" &&
                                                        item.ColorCode !== ""
                                                          ? "white"
                                                          : "black",
                                                    }}
                                                    onClick={() =>
                                                      this.onItemClicked(
                                                        item.DisplayName,
                                                        item.Name,
                                                        "customBtn_" +
                                                          field.toString(),
                                                        item.ColorCode
                                                      )
                                                    }
                                                  >
                                                    <span className="w-100">
                                                      {item.DisplayName}
                                                    </span>
                                                    {inputValue ===
                                                    item.DisplayName ? (
                                                      <span className="fas fa-check custom-dropdown-btn-checkIcon"></span>
                                                    ) : null}
                                                  </div>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                        {allowAdd === true && (
                                          <div className="col-auto">
                                            <ButtonComponent
                                              id={`btnManageChoice_${field.toString()}_${
                                                this.indexVal
                                              }`}
                                              type="button"
                                              cssClass="btn_brand_secondary outline custom-button-lg ms-2"
                                              iconCss="fas fa-plus"
                                              //onClick={() => CreateModalToggle()}
                                            ></ButtonComponent>
                                          </div>
                                        )}
                                      </Fragment>
                                    )}
                                    {displayDropdownType ===
                                      "iconDropdownBtn" && (
                                      <>
                                        <div className="col">
                                          <div
                                            className="iconDropdownBtn_container"
                                            style={{
                                              gridTemplateColumns: `repeat(${
                                                items.length < 6
                                                  ? items.length
                                                  : 5
                                              }, 1fr)`,
                                            }}
                                          >
                                            {items.map((item, index1) => {
                                              return (
                                                <div
                                                  title={item.DisplayName}
                                                  key={`${item.Name}${index1}`}
                                                  id={`dropdown_item_${
                                                    item.Name
                                                  }_${field.toString()}_${
                                                    this.indexVal
                                                  }`}
                                                  className={
                                                    inputValue ===
                                                    item.DisplayName
                                                      ? "iconDropdownBtn_option active"
                                                      : "iconDropdownBtn_option"
                                                  }
                                                  style={{
                                                    background: hexToRGBA(
                                                      item.ColorCode,
                                                      0.1
                                                    ),
                                                    borderColor: item.ColorCode,
                                                    color:
                                                      isNotNullAndUndefined(
                                                        item.ColorCode
                                                      ) &&
                                                      item.ColorCode !==
                                                        "transparent" &&
                                                      item.ColorCode !== ""
                                                        ? item.ColorCode
                                                        : "black",
                                                    stroke:
                                                      isNotNullAndUndefined(
                                                        item.ColorCode
                                                      ) &&
                                                      item.ColorCode !==
                                                        "transparent" &&
                                                      item.ColorCode !== ""
                                                        ? item.ColorCode
                                                        : "black",
                                                    boxShadow:
                                                      inputValue ===
                                                      item.DisplayName
                                                        ? `0 0 0 5px ${hexToRGBA(
                                                            item.ColorCode,
                                                            0.3
                                                          )}`
                                                        : "unset",
                                                  }}
                                                  onClick={() =>
                                                    this.onItemClicked(
                                                      item.DisplayName,
                                                      item.Name,
                                                      "customBtn_" +
                                                        field.toString(),
                                                      item.ColorCode
                                                    )
                                                  }
                                                >
                                                  <div className="d-flex align-items-center justify-content-center flex-column">
                                                    <div
                                                      className={`iconDropdownBtn_icon${
                                                        inputValue ===
                                                        item.DisplayName
                                                          ? " active"
                                                          : ""
                                                      }`}
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          DOMPurify.sanitize(
                                                            RAFTaskTypeWithColor[
                                                              item.Name
                                                            ].svgContent
                                                          ),
                                                      }}
                                                    ></div>

                                                    <div
                                                      className={`ecllipseFirstLine word-break-all`}
                                                    >
                                                      {item.DisplayName}
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                        {allowAdd === true && (
                                          <div className="col-auto">
                                            <ButtonComponent
                                              id={`btnManageChoice_${field.toString()}_${
                                                this.indexVal
                                              }`}
                                              type="button"
                                              cssClass="btn_brand_primary outline custom-button-lg"
                                              iconCss="fas fa-plus"
                                              //onClick={() => CreateModalToggle()}
                                            ></ButtonComponent>
                                          </div>
                                        )}
                                      </>
                                    )}
                                    {displayDropdownType ===
                                      "outlineDropdownBtn" && (
                                      <>
                                        <div className="col">
                                          <div
                                            className="outlineDropdownBtn_container"
                                            style={{
                                              gridTemplateColumns: `repeat(${
                                                items.length < 6
                                                  ? items.length
                                                  : 5
                                              }, 1fr)`,
                                            }}
                                          >
                                            {items.map((item, index1) => {
                                              return (
                                                <div
                                                  title={item.DisplayName}
                                                  key={`${item.Name}${index1}`}
                                                  id={`dropdown_item_${
                                                    item.Name
                                                  }_${field.toString()}_${
                                                    this.indexVal
                                                  }`}
                                                  className={`${
                                                    inputValue ===
                                                    item.DisplayName
                                                      ? "outlineDropdownBtn_option active"
                                                      : "outlineDropdownBtn_option"
                                                  }${
                                                    isNotNullAndUndefined(
                                                      this.props
                                                        .customDropdownBtnClassname
                                                    )
                                                      ? ` ${this.props.customDropdownBtnClassname}`
                                                      : ""
                                                  }`}
                                                  style={{
                                                    background: "#fff",
                                                    borderColor: item.ColorCode,
                                                    color:
                                                      isNotNullAndUndefined(
                                                        item.ColorCode
                                                      ) &&
                                                      item.ColorCode !==
                                                        "transparent" &&
                                                      item.ColorCode !== ""
                                                        ? item.ColorCode
                                                        : "black",
                                                    stroke:
                                                      isNotNullAndUndefined(
                                                        item.ColorCode
                                                      ) &&
                                                      item.ColorCode !==
                                                        "transparent" &&
                                                      item.ColorCode !== ""
                                                        ? item.ColorCode
                                                        : "black",
                                                    boxShadow:
                                                      inputValue ===
                                                      item.DisplayName
                                                        ? `0 0 0 5px ${hexToRGBA(
                                                            item.ColorCode,
                                                            0.3
                                                          )}`
                                                        : "unset",
                                                  }}
                                                  onClick={() =>
                                                    this.onItemClicked(
                                                      item.DisplayName,
                                                      item.Name,
                                                      "customBtn_" +
                                                        field.toString(),
                                                      item.ColorCode
                                                    )
                                                  }
                                                >
                                                  <div
                                                    className="leftBorder_DropdownBtn_option_border"
                                                    style={{
                                                      background:
                                                        item.ColorCode,
                                                    }}
                                                  ></div>
                                                  <div
                                                    className={`ecllipseFirstLine word-break-all`}
                                                  >
                                                    {item.DisplayName}
                                                  </div>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                        {allowAdd === true && (
                                          <div className="col-auto">
                                            <ButtonComponent
                                              id={`btnManageChoice_${field.toString()}_${
                                                this.indexVal
                                              }`}
                                              type="button"
                                              cssClass="btn_brand_primary outline custom-button-lg"
                                              iconCss="fas fa-plus"
                                              //onClick={() => CreateModalToggle()}
                                            ></ButtonComponent>
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </div>
                                )
                              ) : isNotNullAndUndefined(mode) ? (
                                <div className="row g-2 align-items-center">
                                  <Fragment>
                                    <div className="col">
                                      {/* <div
                                                                                        className="customDropdownMenu"
                                                                                        id={
                                                                                            "drodownBtnComponent" +
                                                                                            field.toString() +
                                                                                            "_" +
                                                                                            this.indexVal
                                                                                        }
                                                                                    >
                                                                                        {items.map((item, index1) => {
                                                                                            return (
                                                                                                <div
                                                                                                    key={`${item.Name}${index1}`}
                                                                                                    id={`dropdown_item_${item.Name
                                                                                                        }_${field.toString()}_${this.indexVal
                                                                                                        }`}
                                                                                                    className={
                                                                                                        "dropdown_item colorIcon"
                                                                                                    }
                                                                                                //onClick={() => this.onItemClicked(item.DisplayName, item.Name, 'customBtn_' + field.toString().replace(".", "_"), item.ColorCode)}
                                                                                                >
                                                                                                    {mode === "squareView" ? (
                                                                                                        <i
                                                                                                            className="fas fa-square"
                                                                                                            style={{
                                                                                                                color: item.ColorCode,
                                                                                                            }}
                                                                                                        ></i>
                                                                                                    ) : mode === "iconView" ? (
                                                                                                        <i
                                                                                                            className={iconName}
                                                                                                            style={{
                                                                                                                color: item.ColorCode,
                                                                                                            }}
                                                                                                        ></i>
                                                                                                    ) : (
                                                                                                        <i
                                                                                                            className="fas fa-circle"
                                                                                                            style={{
                                                                                                                color: item.ColorCode,
                                                                                                            }}
                                                                                                        ></i>
                                                                                                    )}
                                                                                                    <span className="w-100 text-truncate">
                                                                                                        {item.DisplayName}
                                                                                                    </span>
                                                                                                    {inputValue ===
                                                                                                        item.DisplayName ? (
                                                                                                        <span className="fas fa-check"></span>
                                                                                                    ) : null}
                                                                                                </div>
                                                                                            );
                                                                                        })}
                                                                                        {createOptionMode === "Footer" &&
                                                                                            allowAdd === true && (
                                                                                                <div className="dropdown_item">
                                                                                                    <ButtonComponent
                                                                                                        id={`btnManageChoice_${field.toString()}_${this.indexVal
                                                                                                            }`}
                                                                                                        type="button"
                                                                                                        iconCss="fas fa-plus"
                                                                                                        className="custom-link-btn primary-custom-button unset-custom-button-md w-100"
                                                                                                    //onClick={() => this.openManageChoiceListDialog()}
                                                                                                    >
                                                                                                        Add
                                                                                                    </ButtonComponent>
                                                                                                </div>
                                                                                            )}
                                                                                    </div> */}

                                      <ListViewComponent
                                        id={
                                          "drodownBtnComponent" +
                                          field.toString() +
                                          "_" +
                                          this.indexVal
                                        }
                                        ref={(g) =>
                                          (this.listViewComponent = g)
                                        }
                                        //select={this.onNodeSelect}
                                        template={this.nodeTemplate}
                                        // dataSource={items}
                                        //fields={{ text: "DisplayName", tooltip: "DisplayName", id: "UID" }}
                                        // fields={fields}
                                        // showCheckBox={true}
                                      />
                                      <div className="d-flex">
                                        <DropDownButtonComponent
                                          id={`ddb_${field.toString()}`}
                                          ref={(d) =>
                                            (this.dropdownBtnComponent = d)
                                          }
                                          onClick={() =>
                                            this.dropdownClick(
                                              "customBtn_" + field.toString(),
                                              "drodownBtnComponent" +
                                                field.toString() +
                                                "_" +
                                                this.indexVal
                                            )
                                          }
                                          target={
                                            "#drodownBtnComponent" +
                                            field.toString() +
                                            "_" +
                                            this.indexVal
                                          }
                                          style={{ fontWeight: 400 }}
                                          // cssClass={
                                          //   this.props
                                          //     .customDropdownBtnClassname
                                          //     ? this.props
                                          //       .customDropdownBtnClassname +
                                          //     " custom-button-lg customDropdownBtn customBtn_" +
                                          //     field.toString()
                                          //     : "custom-button-lg customDropdownBtn customBtn_" +
                                          //     field.toString()
                                          // }
                                          cssClass={`customDropdownBtn customBtn_${field.toString()}${
                                            allowAdd === true ? " allowAdd" : ""
                                          }${
                                            isNotNullAndUndefined(
                                              this.props
                                                .customDropdownBtnClassname
                                            )
                                              ? ` ${this.props.customDropdownBtnClassname}`
                                              : " custom-button-lg"
                                          }`}
                                        >
                                          {IsNullOrWhiteSpace(inputValue) ? (
                                            isNotNullAndUndefined(
                                              emptyString
                                            ) ? (
                                              emptyString
                                            ) : (
                                              <div>None</div>
                                            )
                                          ) : (
                                            <div className="d-flex align-items-center">
                                              {mode === "squareView" ? (
                                                <i
                                                  className="fas fa-square mt-1"
                                                  style={{
                                                    color:
                                                      items &&
                                                      items.find(
                                                        (x) =>
                                                          x.DisplayName ===
                                                          inputValue
                                                      ) &&
                                                      items.find(
                                                        (x) =>
                                                          x.DisplayName ===
                                                          inputValue
                                                      ).ColorCode,
                                                  }}
                                                ></i>
                                              ) : mode === "iconView" ? (
                                                <i
                                                  className={`${iconName} mt-1`}
                                                  style={{
                                                    color:
                                                      items &&
                                                      items.find(
                                                        (x) =>
                                                          x.DisplayName ===
                                                          inputValue
                                                      ) &&
                                                      items.find(
                                                        (x) =>
                                                          x.DisplayName ===
                                                          inputValue
                                                      ).ColorCode,
                                                  }}
                                                ></i>
                                              ) : (
                                                <i
                                                  className="fas fa-circle"
                                                  style={{
                                                    color:
                                                      items &&
                                                      items.find(
                                                        (x) =>
                                                          x.DisplayName ===
                                                          inputValue
                                                      ) &&
                                                      items.find(
                                                        (x) =>
                                                          x.DisplayName ===
                                                          inputValue
                                                      ).ColorCode,
                                                  }}
                                                ></i>
                                              )}
                                              <span className="ecllipseFirstLine pe-3 word-break-all">
                                                {inputValue}
                                              </span>
                                            </div>
                                          )}
                                        </DropDownButtonComponent>
                                        {allowAdd === true && (
                                          <ButtonComponent
                                            id={`btnManageChoice_${field.toString()}_${
                                              this.indexVal
                                            }`}
                                            type="button"
                                            iconCss="fas fa-plus"
                                            cssClass="btn_brand_secondary outline custom-button-lg ms-2"
                                            // className="primary-custom-button dropdownAddBtn custom-button-lg"
                                            onClick={() =>
                                              this.openManageChoiceListDialog()
                                            }
                                          ></ButtonComponent>
                                        )}
                                      </div>
                                      {/*<div className="customDropdownMenu" id={"drodownBtnComponent" + field.toString()}>
                                                {items.map(item => {
                                                    return <div key={item.Name} id={`dropdown_item_${item.Name}`} className="dropdown_item" style={{ background: item.ColorCode, color: isNotNullAndUndefined(item.ColorCode) ? "white" : "black" }} onClick={() => onItemClicked(item.DisplayName, item.Name, 'customBtn_' + field.toString(), item.ColorCode)}><span>{item.DisplayName}</span>{inputValue === item.Name ? <span className="e-icon e-selection"></span> : null}</div>
                                                })}
                                            </div><DropDownButtonComponent ref={d => dropdownBtnComponent = d} target={"#drodownBtnComponent" + field.toString()} style={{ color: isNotNullAndUndefined(items && items.find(x => x.Name === inputValue) && items.find(x => x.Name === inputValue).ColorCode) ? "white" : "black", background: items && items.find(x => x.Name === inputValue) && items.find(x => x.Name === inputValue).ColorCode, fontWeight: 400 }} cssClass={'customDropdownBtn customBtn_' + field.toString()}>{IsNullOrWhiteSpace(inputValue) ? '\u00a0' : getDisplayNamebyName(inputValue)}</DropDownButtonComponent>*/}
                                    </div>
                                    {createOptionMode === "Default" &&
                                      allowAdd === true && (
                                        <div className="col-auto">
                                          <ButtonComponent
                                            id={`btnManageChoice_${field.toString()}_${
                                              this.indexVal
                                            }`}
                                            type="button"
                                            cssClass="btn_brand_secondary outline custom-button-lg ms-2"
                                            iconCss="fas fa-plus"
                                            //onClick={() => CreateModalToggle()}
                                          ></ButtonComponent>
                                        </div>
                                      )}
                                  </Fragment>
                                </div>
                              ) : (
                                <div className="row g-2 align-items-center">
                                  <Fragment>
                                    <div className="col">
                                      <div className="d-flex">
                                        <DropDownButtonComponent
                                          id={`ddb_${field.toString()}`}
                                          target={
                                            "#drodownBtnComponent_" +
                                            field.toString().replace(".", "_") +
                                            "_" +
                                            this.indexVal
                                          }
                                          onClick={() =>
                                            this.dropdownClick(
                                              "customBtn_" + field.toString(),
                                              "drodownBtnComponent_" +
                                                field
                                                  .toString()
                                                  .replace(".", "_") +
                                                "_" +
                                                this.indexVal
                                            )
                                          }
                                          style={{
                                            color:
                                              isNotNullAndUndefined(
                                                items &&
                                                  items.find(
                                                    (x) =>
                                                      x.DisplayName ===
                                                      inputValue
                                                  ) &&
                                                  items.find(
                                                    (x) =>
                                                      x.DisplayName ===
                                                      inputValue
                                                  ).ColorCode
                                              ) &&
                                              items.find(
                                                (x) =>
                                                  x.DisplayName === inputValue
                                              ).ColorCode !== "transparent" &&
                                              items.find(
                                                (x) =>
                                                  x.DisplayName === inputValue
                                              ).ColorCode !== ""
                                                ? "white"
                                                : "black",
                                            background:
                                              items &&
                                              items.find(
                                                (x) =>
                                                  x.DisplayName === inputValue
                                              ) &&
                                              items.find(
                                                (x) =>
                                                  x.DisplayName === inputValue
                                              ).ColorCode,
                                            borderColor:
                                              items &&
                                              items.find(
                                                (x) =>
                                                  x.DisplayName === inputValue
                                              ) &&
                                              items.find(
                                                (x) =>
                                                  x.DisplayName === inputValue
                                              ).ColorCode,
                                            fontWeight: 400,
                                            height:
                                              isNotNullAndUndefined(height) &&
                                              height,
                                          }}
                                          // cssClass={
                                          //   this.props
                                          //     .customDropdownBtnClassname
                                          //     ? this.props
                                          //       .customDropdownBtnClassname +
                                          //     " custom-button-lg customDropdownBtn customBtn_" +
                                          //     field
                                          //       .toString()
                                          //       .replace(".", "_")
                                          //     : "custom-button-lg customDropdownBtn customBtn_" +
                                          //     field
                                          //       .toString()
                                          //       .replace(".", "_")
                                          // }
                                          cssClass={`customDropdownBtn customBtn_${field
                                            .toString()
                                            .replace(".", "_")}${
                                            allowAdd === true ? " allowAdd" : ""
                                          }${
                                            isNotNullAndUndefined(
                                              this.props
                                                .customDropdownBtnClassname
                                            )
                                              ? ` ${this.props.customDropdownBtnClassname}`
                                              : " custom-button-lg"
                                          }`}
                                        >
                                          {IsNullOrWhiteSpace(inputValue) ? (
                                            isNotNullAndUndefined(
                                              emptyString
                                            ) ? (
                                              emptyString
                                            ) : isNotNullAndUndefined(
                                                required
                                              ) && required ? null : (
                                              "None"
                                            )
                                          ) : (
                                            <span className="ecllipseFirstLine pe-3 word-break-all">
                                              {inputValue}
                                            </span>
                                          )}
                                        </DropDownButtonComponent>
                                        {allowAdd === true && (
                                          <ButtonComponent
                                            id={`btnManageChoice_${field.toString()}_${
                                              this.indexVal
                                            }`}
                                            type="button"
                                            iconCss="fas fa-plus"
                                            cssClass="btn_brand_secondary outline custom-button-lg ms-2"
                                            // className="primary-custom-button dropdownAddBtn custom-button-lg"
                                            onClick={() =>
                                              this.openManageChoiceListDialog()
                                            }
                                          ></ButtonComponent>
                                        )}
                                      </div>
                                      <ListViewComponent
                                        id={
                                          "drodownBtnComponent_" +
                                          field.toString().replace(".", "_") +
                                          "_" +
                                          this.indexVal
                                        }
                                        ref={(g) =>
                                          (this.listViewComponent = g)
                                        }
                                        template={this.nodeTemplate}
                                        // dataSource={items}
                                        //select={this.onNodeSelect}
                                        // fields={{ text: "DisplayName", tooltip: "DisplayName", id: "UID" }}
                                        // fields={fields}
                                        // showCheckBox={true}
                                      />
                                      {/* <div
                                                                                        className="customDropdownMenu"
                                                                                        id={
                                                                                            "drodownBtnComponent_" +
                                                                                            field
                                                                                                .toString()
                                                                                                .replace(".", "_") +
                                                                                            "_" +
                                                                                            this.indexVal
                                                                                        }
                                                                                    >
                                                                                        {items.map((item, index1) => {
                                                                                            return (
                                                                                                <div
                                                                                                    key={`${item.Name}${index1}`}
                                                                                                    id={`dropdown_item_${item.Name
                                                                                                        }_${field.toString()}_${this.indexVal
                                                                                                        }`}
                                                                                                    className="dropdown_item"
                                                                                                    style={{
                                                                                                        background: item.ColorCode,
                                                                                                        color:
                                                                                                            isNotNullAndUndefined(
                                                                                                                item.ColorCode
                                                                                                            ) &&
                                                                                                                item.ColorCode !==
                                                                                                                "transparent" &&
                                                                                                                item.ColorCode !== ""
                                                                                                                ? "white"
                                                                                                                : "black",
                                                                                                    }}
                                                                                                //onClick={() => this.onItemClicked(item.DisplayName, item.Name, 'customBtn_' + field.toString(), item.ColorCode)}
                                                                                                >
                                                                                                    <span className="text-truncate">
                                                                                                        {item.DisplayName}
                                                                                                    </span>
                                                                                                    {inputValue ===
                                                                                                        item.DisplayName ? (
                                                                                                        <span className="fas fa-check"></span>
                                                                                                    ) : null}
                                                                                                </div>
                                                                                            );
                                                                                        })}
                                                                                        {createOptionMode === "Footer" &&
                                                                                            allowAdd === true && (
                                                                                                <div className="dropdown_item p-0">
                                                                                                    <ButtonComponent
                                                                                                        id={`btnManageChoice_${field.toString()}_${this.indexVal
                                                                                                            }`}
                                                                                                        type="button"
                                                                                                        iconCss="fas fa-plus"
                                                                                                        className="custom-link-btn primary-custom-button unset-custom-button-md w-100"
                                                                                                    //onClick={() => this.openManageChoiceListDialog()}
                                                                                                    >
                                                                                                        Add
                                                                                                    </ButtonComponent>
                                                                                                </div>
                                                                                            )}
                                                                                    </div> */}
                                      {/*<div className="customDropdownMenu" id={"drodownBtnComponent" + field.toString()}>
                                                {items.map(item => {
                                                    return <div key={item.Name} id={`dropdown_item_${item.Name}`} className="dropdown_item" style={{ background: item.ColorCode, color: isNotNullAndUndefined(item.ColorCode) ? "white" : "black" }} onClick={() => onItemClicked(item.DisplayName, item.Name, 'customBtn_' + field.toString(), item.ColorCode)}><span>{item.DisplayName}</span>{inputValue === item.Name ? <span className="e-icon e-selection"></span> : null}</div>
                                                })}
                                            </div><DropDownButtonComponent ref={d => dropdownBtnComponent = d} target={"#drodownBtnComponent" + field.toString()} style={{ color: isNotNullAndUndefined(items && items.find(x => x.Name === inputValue) && items.find(x => x.Name === inputValue).ColorCode) ? "white" : "black", background: items && items.find(x => x.Name === inputValue) && items.find(x => x.Name === inputValue).ColorCode, fontWeight: 400 }} cssClass={'customDropdownBtn customBtn_' + field.toString()}>{IsNullOrWhiteSpace(inputValue) ? '\u00a0' : getDisplayNamebyName(inputValue)}</DropDownButtonComponent>*/}
                                    </div>
                                    {isNotNullAndUndefined(createOptionMode) &&
                                      isNotNullAndUndefined(allowAdd) &&
                                      createOptionMode === "Default" &&
                                      allowAdd === true && (
                                        <div className="col-auto">
                                          <ButtonComponent
                                            id={`btnManageChoice_${field.toString()}_${
                                              this.indexVal
                                            }`}
                                            type="button"
                                            cssClass="btn_brand_secondary outline custom-button-lg ms-2n"
                                            iconCss="fas fa-plus"
                                            //onClick={() => CreateModalToggle()}
                                          ></ButtonComponent>
                                        </div>
                                      )}
                                  </Fragment>
                                </div>
                              )
                            ) : (
                              <div className="row g-2">
                                <div className="col">
                                  <DropDownListComponent
                                    id={`ddl_${field.toString()}`}
                                    name={input.name}
                                    value={inputValue}
                                    ref={(d) =>
                                      (this.dropDownListComponent = d)
                                    }
                                    change={(e) => {
                                      if (e.isInteracted) {
                                        input.onChange(
                                          e.itemData !== null
                                            ? e.itemData[this.fields.value]
                                            : null
                                        );
                                        if (isNotNullAndUndefined(onChanged)) {
                                          onChanged(
                                            e.itemData !== null
                                              ? e.itemData[this.fields.text]
                                              : null,
                                            e.itemData !== null
                                              ? e.itemData[this.fields.value]
                                              : null
                                          );
                                        }
                                      }
                                    }}
                                    {...(createOptionMode === "Footer" &&
                                    allowAdd === true
                                      ? {
                                          footerTemplate: this.footerTemplate,
                                        }
                                      : {})}
                                    dataSource={items as any}
                                    fields={this.fields}
                                    //delayUpdate
                                    allowFiltering={allowFiltering}
                                    width={width}
                                    readonly={disabled}
                                    filterType="Contains"
                                    popupWidth={this.props.popupWidth}
                                    placeholder={placeholder}
                                    //itemTemplate={isColorOption ? this.itemTemplate : null}
                                    //valueTemplate={isColorOption ? this.valueTemplate : null}
                                    cssClass={`
                        ${
                          meta.error && meta.touched ? "inputFieldError" : null
                        }${
                                      isNotNullAndUndefined(
                                        this.props.componentCssClass
                                      )
                                        ? ` ${this.props.componentCssClass}`
                                        : ""
                                    }
                      dropdown_item_value_${inputValue}`}
                                    {...(showClearButton === true
                                      ? {
                                          showClearButton: showClearButton,
                                        }
                                      : {})}
                                    {...(this.props.uitype === "smileyToggle"
                                      ? {
                                          // itemTemplate: this.itemTemplate,
                                          valueTemplate: this.valueTemplate,
                                        }
                                      : {})}
                                    //{...props}
                                  />
                                </div>
                                {createOptionMode === "Default" &&
                                  allowAdd === true && (
                                    <div className="col-auto">
                                      <ButtonComponent
                                        id={`btnManageChoice_${field.toString()}_${
                                          this.indexVal
                                        }`}
                                        type="button"
                                        cssClass="btn_brand_secondary outline custom-button-lg ms-2n"
                                        iconCss="fas fa-plus"
                                        //onClick={() => CreateModalToggle()}
                                      ></ButtonComponent>
                                    </div>
                                  )}
                              </div>
                            )}
                            {IsNotNullOrWhiteSpace(inputValue) &&
                              otherChoicelistArray.includes(
                                inputValue.toString().toLowerCase()
                              ) && (
                                <div className="col-12 d-flex py-2">
                                  <div className="w-100">
                                    <TextBoxComponent
                                      id={`ttx_${field.toString()}`}
                                      value={
                                        !otherChoicelistArray.includes(
                                          input.value.toString().toLowerCase()
                                        )
                                          ? input.value
                                          : ""
                                      }
                                      name={input.name}
                                      change={(e) =>
                                        this.onChangeTextBoxInputValue(e, input)
                                      }
                                      type="text"
                                      width={"100%"}
                                      showClearButton={true}
                                      cssClass={
                                        meta.error && meta.touched
                                          ? "inputFieldError"
                                          : null
                                      }
                                      placeholder="Other (please specify)"
                                    />
                                  </div>
                                </div>
                              )}
                            {this.props.hideRequiredMessage !== true ? (
                              <RAFFieldError name={field.toString()} />
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      }}
                    </Field>
                  </div>
                </div>
                {this.state.showManageChoiceList && (
                  <DialogComponent
                    header={`Manage : ${label ?? this.field}`}
                    //header={"Manage : " +  label ?? this.field}
                    visible={this.state.showManageChoiceList}
                    cssClass="centerDialog-sm choice-list-dialog" //ref={dialog => this.showLookupGridDialog = dialog}
                    content={this.showManageChoiceListContent.bind(this)}
                    showCloseIcon
                    isModal
                    target="body"
                    closeOnEscape={false}
                    close={this.closeCreateDialog.bind(this)}
                  />
                )}
              </div>
            );
          }}
        </RAFFormContext.Consumer>
      </Fragment>
    );
  }
}

export default RAFDropdownCC;
