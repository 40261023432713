export default [
    {
        id: 1,
        name: 'Head, top  back',
        displayName: 'Top back of head',
        group: 'head',
        displayGroupName: 'Head',
        uid: '87785364-9ebc-4728-b022-c81493a8c72e'
    },
    {
        id: 2,
        name: 'Head, left back',
        displayName: 'Left back of head',
        group: 'head',
        displayGroupName: 'Head',
        uid: 'a9d8418d-b612-4e8e-9523-39869b9db5bd'
    },
    {
        id: 3,
        name: 'Head, right back',
        displayName: 'Right back of head',
        group: 'head',
        displayGroupName: 'Head',
        uid: '864eadd0-31eb-483d-b568-d819ad095467'
    },
    {
        id: 4,
        name: 'Head, lower back',
        displayName: 'Lower back of head',
        group: 'head',
        displayGroupName: 'Head',
        uid: 'a6d11ff9-13a3-4695-8888-606e19b6c0d3'
    },
    {
        id: 5,
        name: 'Ear, left back',
        displayName: 'Left back of ear',
        group: 'face',
        displayGroupName: 'Face',
        uid: '7a8a9e09-c0a2-4155-9cd1-0364247bbbd2'
    },
    {
        id: 6,
        name: 'Ear, right back',
        displayName: 'Right back of ear',
        group: 'face',
        displayGroupName: 'Face',
        uid: 'e8c8497b-c4db-4562-a992-d532241739ce'
    },
    {
        id: 7,
        name: 'Neck, left back',
        displayName: 'Left back of neck',
        group: 'neck',
        displayGroupName: 'Neck',
        uid: '82d72d0a-af08-45fd-a5ea-ae111b01509e'
    },
    {
        id: 8,
        name: 'Neck, right back',
        displayName: 'Right back of neck',
        group: 'neck',
        displayGroupName: 'Neck',
        uid: '62562119-29a5-491c-a07d-43aec1419c02'
    },
    {
        id: 9,
        name: 'Back, middle upper',
        displayName: 'Middle upper back',
        group: 'back',
        displayNroup: 'back',
        displayGroupName: 'Back',
        uid: 'a2dc8c38-a290-4cef-903a-b4ea94d5bde1'
    },
    {
        id: 10,
        name: 'Shoulder, left back',
        displayName: 'Left back of shoulder',
        group: 'neck',
        displayGroupName: 'Neck',
        uid: '93354c79-ad14-4787-8512-6857042fb5c9'
    },
    {
        id: 11,
        name: 'Shoulder, right back',
        displayName: 'Right back of shoulder',
        group: 'neck',
        displayGroupName: 'Neck',
        uid: '9a2ad742-1e29-4115-87bb-0b0d0f2fd547'
    },
    {
        id: 12,
        name: 'Underarm, left',
        displayName: 'Left underarm',
        group: 'arms',
        displayGroupName: 'Arms',
        uid: 'fcc74925-bb30-4a48-8842-bf0dc19e4cdb'
    },
    {
        id: 13,
        name: 'Underarm, right',
        displayName: 'Right underarm',
        group: 'arms',
        displayGroupName: 'Arms',
        uid: '05e9684b-ba86-4482-82af-61467c6e2af6'
    },
    {
        id: 14,
        name: 'Arm, upper left back',
        displayName: 'Left back of upper arm',
        group: 'arms',
        displayGroupName: 'Arms',
        uid: '25788664-72e4-4c45-a6c9-dd4ec2e4ee88'
    },
    {
        id: 15,
        name: 'Arm, upper right back',
        displayName: 'Right back of upper arm',
        group: 'arms',
        displayGroupName: 'Arms',
        uid: '25b32a7c-787f-4862-897a-2e2b61babf39'
    },
    {
        id: 16,
        name: 'Elbow, left',
        displayName: 'Left elbow',
        group: 'arms',
        displayGroupName: 'Arms',
        uid: '48b9cd82-e448-4848-a6ab-f00759c472d6 '
    },
    {
        id: 17,
        name: 'Elbow, right',
        displayName: 'Right elbow',
        group: 'arms',
        displayGroupName: 'Arms',
        uid: 'd271ade8-1855-4c1a-bf57-5c5dddd3e36c'
    },
    {
        id: 18,
        name: 'Wrist, left',
        displayName: 'Left wrist',
        group: 'arms',
        displayGroupName: 'Arms',
        uid: '3d24e3a2-708b-4472-88d8-a83a331d222f'
    },
    {
        id: 19,
        name: 'Wrist, right',
        displayName: 'Right wrist',
        group: 'arms',
        displayGroupName: 'Arms',
        uid: '491a1481-5b0d-4281-861c-9177b5bdbb57'
    },
    {
        id: 20,
        name: 'Forearm, left back',
        displayName: 'Left back of forearm',
        group: 'arms',
        displayGroupName: 'Arms',
        uid: '028821dd-d03a-4a6c-acea-22f57d0d3733'
    },
    {
        id: 21,
        name: 'Forearm, right back',
        displayName: 'Right back of forearm',
        group: 'arms',
        displayGroupName: 'Arms',
        uid: 'bb258a58-810a-4c1d-b6bc-9185f3b1b97c'
    },
    {
        id: 22,
        name: 'Back of Hand, left',
        displayName: 'Left back of hand',
        group: 'arms',
        displayGroupName: 'Arms',
        uid: '534c4388-fb94-424a-8aef-cea6ea107704'
    },
    {
        id: 23,
        name: 'Back of Hand, right',
        displayName: 'Right back of hand',
        group: 'arms',
        displayGroupName: 'Arms',
        uid: 'f97de9fa-207f-4e10-b659-29fcead1482e'
    },
    {
        id: 24,
        name: 'Finger, left little',
        displayName: 'Left little finger',
        group: 'fingers',
        displayGroupName: 'Fingers',
        uid: 'f5fdc02c-78fb-490a-9d44-55db87eb5051'
    },
    {
        id: 25,
        name: 'Finger, right little',
        displayName: 'Right little finger',
        group: 'fingers',
        displayGroupName: 'Fingers',
        uid: 'c1c60374-cc02-4e93-8867-502555913c04'
    },
    {
        id: 26,
        name: 'Finger, left ring',
        displayName: 'Left ring finger',
        group: 'fingers',
        displayGroupName: 'Fingers',
        uid: 'b9be6d85-0d9e-4170-b982-9a5b0511bdfb'
    },
    {
        id: 27,
        name: 'Finger, right ring',
        displayName: 'Right ring finger',
        group: 'fingers',
        displayGroupName: 'Fingers',
        uid: 'fcaa69f6-021a-4eb8-b4b9-6e23ba280c0c'
    },
    {
        id: 28,
        name: 'Finger, left index',
        displayName: 'Left index finger',
        group: 'fingers',
        displayGroupName: 'Fingers',
        uid: 'ad732294-852a-4f68-aabf-916e4dbcd99c '
    },
    {
        id: 29,
        name: 'Finger, right index',
        displayName: 'Right index finger',
        group: 'fingers',
        displayGroupName: 'Fingers',
        uid: 'c7a9ce6b-f1ae-4d64-aa25-789e80e397e2'
    },
    {
        id: 30,
        name: 'Finger, left middle',
        displayName: 'Left middle finger',
        group: 'fingers',
        displayNroup: 'fingers',
        displayGroupName: 'Fingers',
        uid: '862a8f6d-70ab-4325-828e-f856a4c36902'
    },
    {
        id: 31,
        name: 'Finger, right middle',
        displayName: 'Right middle finger',
        group: 'fingers',
        displayNroup: 'fingers',
        displayGroupName: 'Fingers',
        uid: '356c4e76-e103-4edd-8119-3725627b9634'
    },
    {
        id: 32,
        name: 'Thumb, right',
        displayName: 'Right thumb',
        group: 'fingers',
        displayGroupName: 'Fingers',
        uid: '4b488fb7-34db-40a8-85b6-a9103816f80e'
    },
    {
        id: 33,
        name: 'Thumb, left',
        displayName: 'Left thumb',
        group: 'fingers',
        displayGroupName: 'Fingers',
        uid: 'a7b81f96-7c02-4e96-960b-c7726749b7bd'
    },
    {
        id: 34,
        name: 'Spine, lower',
        displayName: 'Lower spine',
        group: 'back',
        displayGroupName: 'Back',
        uid: '5a74b385-7d5a-4a74-a7c2-855e17a21795'
    },
    {
        id: 35,
        name: 'Spine, middle',
        displayName: 'Middle spine',
        group: 'back',
        displayNroup: 'back',
        displayGroupName: 'Back',
        uid: '01f6e20d-76e2-4f0a-a265-b59d1e85267e'
    },
    {
        id: 36,
        name: 'Back, left middle',
        displayName: 'Left middle back',
        group: 'back',
        displayNroup: 'back',
        displayGroupName: 'Back',
        uid: 'f031213a-6afd-4fee-9097-a33eadb568e4'
    },
    {
        id: 37,
        name: 'Back, right middle',
        displayName: 'Right middle back',
        group: 'back',
        displayNroup: 'back',
        displayGroupName: 'Back',
        uid: 'ddc0ea91-d4f3-4dd0-8b57-80112e3ace8a'
    },
    {
        id: 38,
        name: 'Back, left lower',
        displayName: 'Left lower back',
        group: 'back',
        displayGroupName: 'Back',
        uid: '7df33e0e-1eb8-4c5f-afa4-e1416d782da1'
    },
    {
        id: 39,
        name: 'Back, right lower',
        displayName: 'Right lower back',
        group: 'back',
        displayGroupName: 'Back',
        uid: '1d36809f-ca99-4508-9ea6-74fc7c598488'
    },
    {
        id: 40,
        name: 'Buttock, left',
        displayName: 'Left buttock',
        group: 'pelvis',
        displayGroupName: 'Pelvis',
        uid: 'a931d496-38db-48a1-b6cd-051b9c5a4468'
    },
    {
        id: 41,
        name: 'Buttock, right',
        displayName: 'Right buttock',
        group: 'pelvis',
        displayGroupName: 'Pelvis',
        uid: 'b5b47e56-be94-4696-a5a0-a3f63059171b'
    },
    {
        id: 42,
        name: 'Rectum',
        displayName: 'Rectum',
        group: 'pelvis',
        displayGroupName: 'Pelvis',
        uid: '37e89d0b-d4ab-47e4-b4a2-794c7f02f61e'
    },
    {
        id: 43,
        name: 'Thigh, right back',
        displayName: 'Right back thigh',
        group: 'legs',
        displayGroupName: 'Legs',
        uid: 'e6d3000c-7f86-4801-8416-94d50c831a9d'
    },
    {
        id: 44,
        name: 'Thigh, left back',
        displayName: 'Left back thigh',
        group: 'legs',
        displayGroupName: 'Legs',
        uid: '49bf7c71-c7b3-4f0c-a256-93cb01be36d9'
    },
    {
        id: 45,
        name: 'Knee, left back',
        displayName: 'Left back knee',
        group: 'legs',
        displayGroupName: 'Legs',
        uid: 'e786dbca-e3e6-49c2-849a-ef2c0fa3ea33'
    },
    {
        id: 46,
        name: 'Knee, right back',
        displayName: 'Right back knee',
        group: 'legs',
        displayGroupName: 'Legs',
        uid: '5567f160-5c38-4534-a89a-5cdc9f921cca'
    },
    {
        id: 47,
        name: 'Calf, left',
        displayName: 'Left calf',
        group: 'legs',
        displayGroupName: 'Legs',
        uid: '62cf4c96-3b03-48a7-b50d-ce7c83f44d7d'
    },
    {
        id: 48,
        name: 'Calf, right',
        displayName: 'Right calf',
        group: 'legs',
        displayGroupName: 'Legs',
        uid: '23be3e52-35aa-4a8d-9c17-7d14834464dc'
    },
    {
        id: 49,
        name: 'Leg, left lower back',
        displayName: 'Left lower back leg',
        group: 'legs',
        displayGroupName: 'Legs',
        uid: '1f263578-44f0-4161-a95f-efd4fec1d076'
    },
    {
        id: 50,
        name: 'Leg, right lower back',
        displayName: 'Right lower back leg',
        group: 'legs',
        displayGroupName: 'Legs',
        uid: '2b82fc31-8054-4535-bf32-86561136365e'
    },
    {
        id: 51,
        name: 'Heel, left',
        displayName: 'Left heel',
        group: 'legs',
        displayGroupName: 'Legs',
        uid: 'a6b4052c-a8d6-420f-aefe-1ac6cdac1fb3'
    },
    {
        id: 52,
        name: 'Heel, right',
        displayName: 'Right heel',
        group: 'legs',
        displayGroupName: 'Legs',
        uid: 'cf8db674-a734-45e0-92f4-d7f5a1b1e3b5'
    },
    {
        id: 53,
        name: 'Toe, left big',
        displayName: 'Left big toe',
        group: 'toes',
        displayGroupName: 'Toes',
        uid: '6915c65e-1c88-4121-b8e5-c9ebd9762a54'
    },
    {
        id: 54,
        name: 'Toe, right big',
        displayName: 'Right big toe',
        group: 'toes',
        displayGroupName: 'Toes',
        uid: 'c8705b0a-7c88-4376-9dab-ad22effdec4b'
    },
    {
        id: 55,
        name: 'Toe, left index',
        displayName: 'Left index toe',
        group: 'toes',
        displayGroupName: 'Toes',
        uid: '2f90b057-7d9e-48a8-9178-07e546c29edc'
    },
    {
        id: 56,
        name: 'Toe, right index',
        displayName: 'Right index toe',
        group: 'toes',
        displayGroupName: 'Toes',
        uid: '619c9985-3cc6-4793-89e4-833a406a9b50'
    },
    {
        id: 57,
        name: 'Toe, left middle',
        displayName: 'Left middle toe',
        group: 'toes',
        displayNroup: 'toes',
        displayGroupName: 'Toes',
        uid: '1b6f5b77-7dc8-4bd5-b90d-d63b869ce28f'
    },
    {
        id: 58,
        name: 'Toe, right middle',
        displayName: 'Right middle toe',
        group: 'toes',
        displayNroup: 'toes',
        displayGroupName: 'Toes',
        uid: 'b6bbebb1-9436-4690-be80-8157a90070bd'
    },
    {
        id: 59,
        name: 'Toe, left third',
        displayName: 'Left third toe',
        group: 'toes',
        displayGroupName: 'Toes',
        uid: '422a6be9-2157-4131-b672-ebfe495a5696'
    },
    {
        id: 60,
        name: 'Toe, right third',
        displayName: 'Right third toe',
        group: 'toes',
        displayGroupName: 'Toes',
        uid: '0191fbac-0cab-4740-b83e-5676e34867c9'
    },
    {
        id: 61,
        name: 'Toe, left little',
        displayName: 'Left little toe',
        group: 'toes',
        displayGroupName: 'Toes',
        uid: 'f3bf7206-02ab-4a03-bd70-6a0153558adb'
    },
    {
        id: 62,
        name: 'Toe, right little',
        displayName: 'Right little toe',
        group: 'toes',
        displayGroupName: 'Toes',
        uid: '5ff842e4-a77c-4f52-8343-71778169a370'
    },
    {
        id: 63,
        name: 'Foot, left',
        displayName: 'Left foot',
        group: 'legs',
        displayGroupName: 'Legs',
        uid: 'de7a2826-0e29-40df-be10-44705ac1d66f'
    },
    {
        id: 64,
        name: 'Foot, right',
        displayName: 'Right foot',
        group: 'legs',
        displayGroupName: 'Legs',
        uid: '37c758a0-3de9-46e5-9e2e-bf3577ee7d71'
    },
    {
        id: 65,
        name: 'Ankle, left',
        displayName: 'Left ankle',
        group: 'legs',
        displayGroupName: 'Legs',
        uid: 'c1fa5796-24bb-4be7-ba87-5a8c638ee8cf'
    },
    {
        id: 66,
        name: 'Ankle, right',
        displayName: 'Right ankle',
        group: 'legs',
        displayGroupName: 'Legs',
        uid: '16ee9624-951d-4ab8-aecf-f6c6f9b452f8'
    },
    {
        id: 67,
        name: 'Shin, left',
        displayName: 'Left shin',
        group: 'legs',
        displayGroupName: 'Legs',
        uid: 'ceb0c6de-a590-411e-aacd-ce60dabd8f87'
    },
    {
        id: 68,
        name: 'Shin, right',
        displayName: 'Right shin',
        group: 'legs',
        displayGroupName: 'Legs',
        uid: '9b4c0371-6fe3-4242-8a22-7a9ae696f237'
    },
    {
        id: 69,
        name: 'Knee, left',
        displayName: 'Left knee',
        group: 'legs',
        displayGroupName: 'Legs',
        uid: '4c8c7a4b-8804-45dc-9c8b-76283bf6b637'
    },
    {
        id: 70,
        name: 'Knee, right',
        displayName: 'Right knee',
        group: 'legs',
        displayGroupName: 'Legs',
        uid: '02bac8f4-7aa9-409e-83a3-bc4c9183d105'
    },
    {
        id: 71,
        name: 'Thigh, left',
        displayName: 'Left thigh',
        group: 'legs',
        displayGroupName: 'Legs',
        uid: '022458de-85a0-4816-a0c5-2b2cf367ad6d'
    },
    {
        id: 72,
        name: 'Thigh, right',
        displayName: 'Right thigh',
        group: 'legs',
        displayGroupName: 'Legs',
        uid: 'c8325082-f010-4c16-b5f2-0096b697acd8'
    },
    {
        id: 73,
        name: 'Groin',
        displayName: 'Groin',
        group: 'pelvis',
        displayGroupName: 'Pelvis',
        uid: 'ed36e273-10ae-48b7-86b4-e90bfbf5a64e'
    },
    {
        id: 74,
        name: 'Hip, left',
        displayName: 'Left hip',
        group: 'pelvis',
        displayGroupName: 'Pelvis',
        uid: '314699eb-ef9c-410f-8f1a-ff39fd8f5a42'
    },
    {
        id: 75,
        name: 'Hip, right',
        displayName: 'Right hip',
        group: 'pelvis',
        displayGroupName: 'Pelvis',
        uid: 'b98e76ed-a5a9-4dad-80c6-e2b0eefdf5eb'
    },
    {
        id: 76,
        name: 'Forehead, left',
        displayName: 'Left forehead',
        group: 'head',
        displayGroupName: 'Head',
        uid: 'aaf46316-80e6-43ec-ba38-52ae6618bb4f'
    },
    {
        id: 77,
        name: 'Forehead, right',
        displayName: 'Right forehead',
        group: 'head',
        displayGroupName: 'Head',
        uid: 'e9b7519f-1779-4e83-9be5-449d7109fccb'
    },
    {
        id: 78,
        name: 'Head, front top',
        displayName: 'Front top of head',
        group: 'head',
        displayGroupName: 'Head',
        uid: '3a180a28-c626-40ea-a70f-ae415d3f92f1'
    },
    {
        id: 79,
        name: 'Temple, left',
        displayName: 'Left temple',
        group: 'head',
        displayGroupName: 'Head',
        uid: '244d212a-32ff-4da6-892d-999b8f49b0ba'
    },
    {
        id: 80,
        name: 'Temple, right',
        displayName: 'Right temple',
        group: 'head',
        displayGroupName: 'Head',
        uid: '84b5dd24-5838-4fb1-ad77-f121c9ea3b92'
    },
    {
        id: 81,
        name: 'Eye, left',
        displayName: 'Left eye',
        group: 'face',
        displayGroupName: 'Face',
        uid: '2c278d6e-f025-4819-92c8-204ce4bc13ab'
    },
    {
        id: 82,
        name: 'Eye, right',
        displayName: 'Right eye',
        group: 'face',
        displayGroupName: 'Face',
        uid: '2e130d8f-7e22-4b05-a4c0-bb6621cd6bb2'
    },
    {
        id: 83,
        name: 'Ear, left',
        displayName: 'Left ear',
        group: 'face',
        displayGroupName: 'Face',
        uid: 'd835eeaa-8985-47aa-9944-0c097c1ba883'
    },
    {
        id: 84,
        name: 'Ear, right',
        displayName: 'Right ear',
        group: 'face',
        displayGroupName: 'Face',
        uid: '758d56c5-e449-43a6-9dee-badbd92c0eee'
    },
    {
        id: 85,
        name: 'Mouth',
        displayName: 'Mouth',
        group: 'face',
        displayGroupName: 'Face',
        uid: 'ad3b709c-8e3c-45e7-94e0-8bf2ebaf2ce1'
    },
    {
        id: 86,
        name: 'Nose',
        displayName: 'Nose',
        group: 'face',
        displayGroupName: 'Face',
        uid: '11995fa3-3335-4451-8cff-ff02254f08e1'
    },
    {
        id: 87,
        name: 'Cheek, left',
        displayName: 'Left cheek',
        group: 'face',
        displayGroupName: 'Face',
        uid: 'eb6e044d-8c5b-4484-8103-ede507ab1a2b'
    },
    {
        id: 88,
        name: 'Cheek, right',
        displayName: 'Right cheek',
        group: 'face',
        displayGroupName: 'Face',
        uid: 'f48f14f6-182a-46ee-9508-1410e5b34e4d'
    },
    {
        id: 89,
        name: 'Chin',
        displayName: 'Chin',
        group: 'face',
        displayGroupName: 'Face',
        uid: 'f248808f-724c-4688-9e8d-e698513e40f3'
    },
    {
        id: 90,
        name: 'Neck',
        displayName: 'Neck',
        group: 'neck',
        displayGroupName: 'Neck',
        uid: '5cc73eeb-46c2-4978-91ea-1d00f42c971f'
    },
    {
        id: 91,
        name: 'Shoulder, left',
        displayName: 'Left shoulder',
        group: 'neck',
        displayGroupName: 'Neck',
        uid: 'f22ac25e-5cc0-4e31-b714-2685327cbca8'
    },
    {
        id: 92,
        name: 'Shoulder, right',
        displayName: 'Right shoulder',
        group: 'neck',
        displayGroupName: 'Neck',
        uid: '723cf19c-75fa-4c84-844a-9cf796c8276c'
    },
    {
        id: 93,
        name: 'Collar bone',
        displayName: 'Collar bone',
        group: 'neck',
        displayGroupName: 'Neck',
        uid: '75dae283-83ac-4768-8bdb-de3cd15b8c87'
    },
    {
        id: 94,
        name: 'Cheek bone, right',
        displayName: 'Right cheek bone',
        group: 'face',
        displayGroupName: 'Face',
        uid: '984b27b9-d06f-4121-a99a-b6da0c4fcbdd'
    },
    {
        id: 95,
        name: 'Cheek bone, left',
        displayName: 'Left cheek boke',
        group: 'face',
        displayGroupName: 'Face',
        uid: '04f29df5-1b65-4a8d-8653-b2368293e592'
    },
    {
        id: 96,
        name: 'Chest, centre',
        displayName: 'Centre chest',
        group: 'chest',
        displayGroupName: 'Chest',
        uid: '31b99c1d-1add-456a-a1e4-6de2f41e77cc'
    },
    {
        id: 97,
        name: 'Arm, left upper',
        displayName: 'Left upper arm',
        group: 'arms',
        displayGroupName: 'Arms',
        uid: '49727f3e-5624-4bb1-8eb8-09ce91aef28b'
    },
    {
        id: 98,
        name: 'Arm, right upper',
        displayName: 'Right upper arm',
        group: 'arms',
        displayGroupName: 'Arms',
        uid: '99438cae-4720-4a56-bee3-7999640a8e93'
    },
    {
        id: 99,
        name: 'Stomach',
        displayName: 'Stomach',
        group: 'abdomen',
        displayGroupName: 'Abdomen',
        uid: 'd6f8a920-212a-4328-b8a1-b4b461e7e09b'
    },
    {
        id: 100,
        name: 'Elbow, left inside',
        displayName: 'Left inside of elbow',
        group: 'arms',
        displayNroup: 'arms',
        displayGroupName: 'Arms',
        uid: '325399d1-3263-419b-8d4f-d3828e92102f'
    },
    {
        id: 101,
        name: 'Elbow, right inside',
        displayName: 'Right inside of elbow',
        group: 'arms',
        displayNroup: 'arms',
        displayGroupName: 'Arms',
        uid: 'f49e6b93-9b6d-44ed-a688-45321ce7f931'
    },
    {
        id: 102,
        name: 'Abdomen, left',
        displayName: 'Left abdomen',
        group: 'abdomen',
        displayGroupName: 'Abdomen',
        uid: '7df39f1a-6b4a-4ddf-9921-3996d805c49d'
    },
    {
        id: 103,
        name: 'Abdomen, right',
        displayName: 'Right abdomen',
        group: 'abdomen',
        displayGroupName: 'Abdomen',
        uid: '61e83b58-6a75-4e30-9c24-7f34c04efe34'
    },
    {
        id: 104,
        name: 'Chest, upper',
        displayName: 'Upper chest',
        group: 'chest',
        displayGroupName: 'Chest',
        uid: '264d4a29-d73b-4232-9b97-1e85713a3715'
    },
    {
        id: 105,
        name: 'Chest, right',
        displayName: 'Right chest',
        group: 'chest',
        displayGroupName: 'Chest',
        uid: '0f2db237-9499-47aa-b6b6-16750b63da5c'
    },
    {
        id: 106,
        name: 'Chest, left',
        displayName: 'Left chest',
        group: 'chest',
        displayGroupName: 'Chest',
        uid: 'f342b33d-2b58-40a0-ae62-05fb700cc204'
    },
    {
        id: 107,
        name: 'Wrist, right back',
        displayName: 'Right back of wrist',
        group: 'arms',
        displayGroupName: 'Arms',
        uid: 'b9142439-f7de-4254-b6a9-65d02e6d1bb3'
    },
    {
        id: 108,
        name: 'Wrist, left back',
        displayName: 'Left back of wrist',
        group: 'arms',
        displayGroupName: 'Arms',
        uid: '4672ae6a-bfd1-46fe-a2c4-7841f04b9833'
    },
    {
        id: 109,
        name: 'Forearm, left front',
        displayName: 'Left front of forearm',
        group: 'arms',
        displayGroupName: 'Arms',
        uid: '569f452e-da6f-4e32-925c-4fb3b5c4281b'
    },
    {
        id: 110,
        name: 'Forearm, right front',
        displayName: 'Right front of forearm',
        group: 'arms',
        displayGroupName: 'Arms',
        uid: '66b370c6-3d55-4b24-a853-4a1490e3a175'
    },
    {
        id: 111,
        name: 'Hand, left',
        displayName: 'Left hand',
        group: 'arms',
        displayGroupName: 'Arms',
        uid: '0b7891b4-f605-4d66-af52-299739e00dcf'
    },
    {
        id: 112,
        name: 'Hand, right',
        displayName: 'Right hand',
        group: 'arms',
        displayGroupName: 'Arms',
        uid: 'ddf1ef4d-14f8-451f-a469-d5a36a0eb57a'
    },
    {
        id: 113,
        name: 'Organs, internal',
        displayName: 'Internal organs',
        group: 'other',
        displayGroupName: 'Other',
        uid: '794a684e-c1fc-47b4-b2b2-d7aa91ab894b'
    },
    {
        id: 114,
        name: 'General & Unspecified',
        displayName: 'General & Unspecified',
        group: 'other',
        displayGroupName: 'Other',
        uid: 'ce63c0bd-5e0a-4830-a665-8e05d3949b4d '
    },
    {
        id: 115,
        name: 'Head, middle back',
        displayName: 'Middle back of head',
        group: 'head',
        displayNroup: 'head',
        displayGroupName: 'Head',
        uid: 'de932178-0fb8-4222-be0f-82432ea29e00'
    },
];
