import React, { useState } from "react";
import BodyPartSidebar from "./BodyPartSidebar";

import femaleBackBodyParts from "./constants/female-back-svg-data";
import femaleFrontBodyParts from "./constants/female-front-svg-data";
import femaleBodyParts from "./constants/female-svg-data";
import maleBackBodyParts from "./constants/male-back-svg-data";
import maleFrontBodyParts from "./constants/male-front-svg-data";
import maleBodyParts from "./constants/male-svg-data";
import otherBackBodyParts from "./constants/other-back-svg-data";
import otherFrontBodyParts from "./constants/other-front-svg-data";
import otherBodyParts from "./constants/other-svg-data";

import { BodyPart } from "../../helpers/types";
import { isNotNullAndUndefined } from "../../helpers/utils";
import "./body_parts_picker.scss";
import femaleBackBody from "./images/body/female-back.png";
import femaleFrontBody from "./images/body/female-front.png";
import maleBackBody from "./images/body/male-back.png";
import maleFrontBody from "./images/body/male-front.png";
import otherBackBody from "./images/body/other-back.png";
import otherFrontBody from "./images/body/other-front.png";
import "./style.css";


interface BodyPartSVG {
  id: number;
  cx: string;
  cy: string;
  r?: string;
}

interface BodyPartSVGEllipse extends BodyPartSVG {
  rx: string;
  ry: string;
  transform?: string;
}

interface BodyPartPickerProps {
  bodyParts: Map<number, { selected: boolean; data: BodyPart; }>;
  selectedBodyPartsCount: number;
  gender: "Male" | "Female" | "Other";
  handleGenderChange: (string) => void;
  handleBodyPartClick: (number) => void;
}

const tabItems = [
  {
    label: 'Front View',
    id: 'Front'
  },
  {
    label: 'Back View',
    id: 'Back'
  }
];

const BodyPartPicker: React.FC<BodyPartPickerProps> = ({
  bodyParts,
  selectedBodyPartsCount,
  gender,
  handleGenderChange,
  handleBodyPartClick,
}) => {
  const [bodyPartHover, setBodyPartHover] = useState<number>(null);

  const [selectedTab, setSelectedTab] = useState(tabItems[0]);

  const handleTabChange = (item) => {
    setSelectedTab(item);
  };

  const bodyPartSVGElements = () => {
    let svgElements: JSX.Element[] = [];
    let bodyPartSVGData;
    let bodyFrontPartSVGData;
    let bodyBackPartSVGData;

    if (gender === "Male") {
      bodyPartSVGData = maleBodyParts;
      bodyFrontPartSVGData = maleFrontBodyParts;
      bodyBackPartSVGData = maleBackBodyParts;
    } else if (gender === "Female") {
      bodyPartSVGData = femaleBodyParts;
      bodyFrontPartSVGData = femaleFrontBodyParts;
      bodyBackPartSVGData = femaleBackBodyParts;
    } else if (gender === "Other") {
      bodyPartSVGData = otherBodyParts;
      bodyFrontPartSVGData = otherFrontBodyParts;
      bodyBackPartSVGData = otherBackBodyParts;
    }
    if (selectedTab.id === 'Front') {

      bodyFrontPartSVGData.circles.map((partSVGData: BodyPartSVG) => {
        bodyParts.has(partSVGData.id) &&
          svgElements.push(
            <circle
              className={
                bodyParts.get(partSVGData.id).selected ||
                  bodyPartHover === partSVGData.id
                  ? "body-part--selected"
                  : "body-part-picker__main__container__svg-canvas__body-part"
              }
              key={partSVGData.id}
              cx={partSVGData.cx}
              cy={partSVGData.cy}
              r={partSVGData.r}
              onMouseEnter={() => setBodyPartHover(partSVGData.id)}
              onMouseLeave={() => setBodyPartHover(null)}
              onClick={() => handleBodyPartClick(partSVGData.id)}
              id={"svgBodyPart_" + partSVGData.id}
            />
          );
      });

      bodyFrontPartSVGData.ellipses.map((partSVGData: BodyPartSVGEllipse) => {
        bodyParts.has(partSVGData.id) &&
          svgElements.push(
            <ellipse
              className={
                bodyParts.get(partSVGData.id).selected ||
                  bodyPartHover === partSVGData.id
                  ? "body-part--selected"
                  : "body-part-picker__main__container__svg-canvas__body-part"
              }
              key={partSVGData.id}
              cx={partSVGData.cx}
              cy={partSVGData.cy}
              rx={partSVGData.rx}
              ry={partSVGData.ry}
              transform={partSVGData.transform}
              onMouseEnter={() => setBodyPartHover(partSVGData.id)}
              onMouseLeave={() => setBodyPartHover(null)}
              onClick={() => handleBodyPartClick(partSVGData.id)}
              id={"svgBodyPart_" + partSVGData.id}
            />
          );
      });
    }
    else {

      bodyBackPartSVGData.circles.map((partSVGData: BodyPartSVG) => {
        bodyParts.has(partSVGData.id) &&
          svgElements.push(
            <circle
              className={
                bodyParts.get(partSVGData.id).selected ||
                  bodyPartHover === partSVGData.id
                  ? "body-part--selected"
                  : "body-part-picker__main__container__svg-canvas__body-part"
              }
              key={partSVGData.id}
              cx={partSVGData.cx}
              cy={partSVGData.cy}
              r={partSVGData.r}
              onMouseEnter={() => setBodyPartHover(partSVGData.id)}
              onMouseLeave={() => setBodyPartHover(null)}
              onClick={() => handleBodyPartClick(partSVGData.id)}
              id={"svgBodyPart_" + partSVGData.id}
            />
          );
      });

      bodyBackPartSVGData.ellipses.map((partSVGData: BodyPartSVGEllipse) => {
        bodyParts.has(partSVGData.id) &&
          svgElements.push(
            <ellipse
              className={
                bodyParts.get(partSVGData.id).selected ||
                  bodyPartHover === partSVGData.id
                  ? "body-part--selected"
                  : "body-part-picker__main__container__svg-canvas__body-part"
              }
              key={partSVGData.id}
              cx={partSVGData.cx}
              cy={partSVGData.cy}
              rx={partSVGData.rx}
              ry={partSVGData.ry}
              transform={partSVGData.transform}
              onMouseEnter={() => setBodyPartHover(partSVGData.id)}
              onMouseLeave={() => setBodyPartHover(null)}
              onClick={() => handleBodyPartClick(partSVGData.id)}
              id={"svgBodyPart_" + partSVGData.id}
            />
          );
      });
    }
    return svgElements;
  };

  const BindButtonEvents = () => {
    ["Male", "Female", "Other"].forEach((item, index) => {
      const objElement: HTMLElement = document.getElementById(
        `raf_gender_tab_header_item_${item}`
      );
      if (isNotNullAndUndefined(objElement)) {
        objElement.onclick = () => {
          handleGenderChange(item);
        };
      }
    });
    tabItems.forEach((item, index) => {
      const objElement: HTMLElement = document.getElementById(
        `raf_front_back_tab_header_item_${item.id}`
      );
      if (isNotNullAndUndefined(objElement)) {
        objElement.onclick = () => {
          handleTabChange(item);
        };
      }
    });
  };

  const BindBodyPartEvents = (objElements1: JSX.Element[]) => {
    objElements1.forEach((item, index) => {
      const objElement: HTMLElement = document.getElementById(item.props.id);
      if (isNotNullAndUndefined(objElement)) {
        objElement.onclick = () => {
          handleBodyPartClick(Number(item.key.toString()));
        };
      }
    });
  };

  const init = (objElements1: JSX.Element[]) => {
    BindButtonEvents();
    BindBodyPartEvents(objElements1);
  };

  const objElements = bodyPartSVGElements();
  setTimeout(() => {
    init(objElements);
  }, 500);

  const genderTabItems = [
    {
      id: 'Male',
      text: 'Male'
    },
    {
      id: 'Female',
      text: 'Female'
    },
    {
      id: 'Other',
      text: 'Non-Binary'
    }
  ];

  return (
    <div className="e-dlg-content-outer">

      <div className="row gx-1 align-items-center flex-nowrap">
        <div className="col">
          <div
            id="raf_front_back_tab_header_container"
            className="raf_tab_header hideScrollBar"
          >
            <div id="raf_front_back_tab_header" className="raf_tab_header hideScrollBar">
              {genderTabItems.map((x) => {
                return (
                  <div
                    id={`raf_gender_tab_header_item_${x.id}`}
                    className={`raf_tab_header_item${gender === x.id
                      ? " active"
                      : ""
                      }`}
                    key={x.id}
                    onClick={() => handleGenderChange(x.id)}
                  >
                    <span>{x.text}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="col-auto">
          <div className="body_2 pe-2">
            {selectedBodyPartsCount === 0
              ? "Not selected"
              : `${selectedBodyPartsCount} selected`}
          </div>
        </div>
      </div>
      <div className="custom__card__separator custom__card__separator__light"></div>
      <div className="px-2 py-1">
        <div
          id="raf_front_back_tab_header_container"
          className="raf_tab_header hideScrollBar full-oval-tab"
        >
          <div id="raf_front_back_tab_header" className="raf_tab_header hideScrollBar full-oval-tab">
            {tabItems.map((x) => {
              return (
                <div
                  id={`raf_front_back_tab_header_item_${x.id}`}
                  className={`raf_tab_header_item${selectedTab.id === x.id
                    ? " active"
                    : ""
                    }`}
                  key={x.id}
                  onClick={() => handleTabChange(x)}
                >
                  <span>{x.label}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="e-dlg-body-content">
        <div className="body-part-picker">
          <div className="body-part-picker__main">
            {/* <div className="body-part-picker__main__header">
          {/* <div className="body-part-picker__main__gender-selection">
            {["Male", "Female", "Other"].map(
              (gender: "Male" | "Female" | "Other") => {
                return (
                  <ButtonComponent
                    id={"buttonGender" + gender}
                    type="button"
                    cssClass="e-flat e-primary primary-btn me-2 mb-2"
                    key={gender}
                    onClick={() => handleGenderChange(gender)}
                  >
                    {gender}
                  </ButtonComponent>
                );
              }
            )}
          </div> */}
            {/*<div style={{ fontSize: "1.125rem", fontWeight: 600 }}>
            {selectedBodyPartsCount === 0
              ? "No body part selected"
              : `${selectedBodyPartsCount} body part(s) selected`}
          </div>
        </div> */}
            {/* <div className="body-part-picker__main_name-container">
          {bodyPartHover !== null
            ? bodyParts.get(bodyPartHover).data.displayName
            : null}
        </div> */}
            <div className="body-part-picker__main__container">
              {/* <div className="tabs">
            {tabItems.map(item => {
              return (
                <ButtonComponent
                    id={"buttonGender" + item.id}
                    type="button"
                    cssClass="e-flat e-primary primary-btn me-2 mb-2"
                key={`${item.id}_${selectedTab.id}`}
                    onClick={() => handleTabChange(item)}
                  >
                    {item.label}
                  </ButtonComponent>
              )
            })}
          </div> */}
              {/* // <div className={`tab${selectedTab.id === item.id ? ' active' : ''}`} >{item.label}</div> */}
              <div className="h-100">
                <svg className="body-part-picker__main__container__svg-canvas"
                  viewBox="0 0 1020 1900"
                // viewBox="0 0 4090 1900"
                >
                  {selectedTab.id === 'Front' ?
                    <image x="0" y="0" href={
                      gender === "Male"
                        ? maleFrontBody
                        : gender === "Female"
                          ? femaleFrontBody
                          : otherFrontBody
                    }></image>
                    :
                    <image x="0" y="0" href={
                      gender === "Male"
                        ? maleBackBody
                        : gender === "Female"
                          ? femaleBackBody
                          : otherBackBody
                    }></image>
                  }
                  <g className={`${selectedTab.id} ${gender}`}>{objElements}</g>
                </svg>
              </div>
              {/* <svg
            className="body-part-picker__main__container__svg-canvas"
            viewBox="0 0 2045 1900"
          >
            <image
              x="0"
              y="0"
              href={
                gender === "Male"
                  ? maleBody
                  : gender === "Female"
                  ? femaleBody
                  : otherBody
              }
            />
            <g className="front-view">{objElements}</g>
          </svg> */}
            </div>
          </div>
          <BodyPartSidebar
            bodyParts={bodyParts}
            handleBodyPartClick={handleBodyPartClick}
          />
        </div>
      </div>
    </div>
  );
};

export default BodyPartPicker;
