// prettier-ignore
export default {
	circles: [
		{
			id: 8,
			cx: '597.1626',
			cy: '372.59433',
			r: '20.503029',
		},
		{
			id: 7,
			cx: '532.82538',
			cy: '372.60477',
			r: '21.439465',
		},
		{
			id: 9,
			cx: '567.85852',
			cy: '457.69644',
			r: '42.739182',
		},
		{
			id: 16,
			cx: '351.5517',
			cy: '704.07605',
			r: '24.403927',
		},
		{
			id: 17,
			cx: '783.04816',
			cy: '700.09741',
			r: '25.106253',
		},
		{
			id: 12,
			cx: '1339.9978',
			cy: '543.18829',
			r: '23.517534',
		},
		{
			id: 13,
			cx: '1610.8927',
			cy: '530.58728',
			r: '21.862135',
		},
		{
			id: 22,
			cx: '204.6499',
			cy: '936.64795',
			r: '27.007568',
		},
		{
			id: 23,
			cx: '927.34137',
			cy: '934.7749',
			r: '28.412222',
		},
		{
			id: 42,
			cx: '565.20844',
			cy: '1001.1868',
			r: '31.45219',
		},
		{
			id: 45,
			cx: '474.08047',
			cy: '1347.8165',
			r: '29.537319',
		},
		{
			id: 46,
			cx: '660.47723',
			cy: '1349.2656',
			r: '28.828098',
		},
		{
			id: 51,
			cx: '465.02121',
			cy: '1768.7753',
			r: '27.615551',
		},
		{
			id: 52,
			cx: '665.36938',
			cy: '1766.4685',
			r: '27.849661',
		},
		{
			id: 69,
			cx: '1568.8328',
			cy: '1277.6429',
			r: '30.066895',
		},
		{
			id: 70,
			cx: '1385.5361',
			cy: '1280.9204',
			r: '31.47155',
		},
		{
			id: 73,
			cx: '1473.0889',
			cy: '993.44788',
			r: '28.630741',
		},
		{
			id: 76,
			cx: '1497.0148',
			cy: '186.90883',
			r: '13.352617',
		},
		{
			id: 77,
			cx: '1443.1046',
			cy: '187.44391',
			r: '14.014777',
		},
		{
			id: 79,
			cx: '1519.8762',
			cy: '199.63197',
			r: '10.129114',
		},
		{
			id: 80,
			cx: '1419.2499',
			cy: '199.68877',
			r: '10.460195',
		},
		{
			id: 87,
			cx: '1514.7732',
			cy: '277.15591',
			r: '13.79553',
		},
		{
			id: 88,
			cx: '1434.4684',
			cy: '278.69772',
			r: '14.12661',
		},
		{
			id: 95,
			cx: '1515.9481',
			cy: '321.7702',
			r: '11.860545',
		},
		{
			id: 94,
			cx: '1434.6249',
			cy: '325.73343',
			r: '12.191626',
		},
		{
			id: 100,
			cx: '1690.7773',
			cy: '694.11401',
			r: '22.448265',
		},
		{
			id: 101,
			cx: '1252.3538',
			cy: '702.34106',
			r: '23.772585',
		},
		{
			id: 111,
			cx: '1824.839',
			cy: '921.3894',
			r: '23.328659',
		},
		{
			id: 112,
			cx: '1123.6848',
			cy: '922.36896',
			r: '24.937002',
		},
	],
	ellipses: [
		{
			id: 1,
			cx: '569.03546',
			cy: '147.93796',
			rx: '63.375931',
			ry: '17.06275',
		},
		{
			id: 2,
			cx: '521.50543',
			cy: '203.87869',
			rx: '23.593699',
			ry: '24.354786',
		},
		{
			id: 3,
			cx: '621.41449',
			cy: '203.17212',
			rx: '23.783913',
			ry: '24.551134',
		},
		{
			id: 5,
			cx: '378.16183',
			cy: '379.272',
			rx: '14.692904',
			ry: '29.312397',
			transform: 'matrix(0.97165925,-0.23638591,0.31100489,0.95040831,0,0)',
		},
		{
			id: 6,
			cx: '698.76428',
			cy: '10.464813',
			rx: '13.786596',
			ry: '27.504311',
			transform: 'matrix(0.92307939,0.38460947,-0.3118877,0.95011897,0,0)',
		},
		{
			id: 4,
			cx: '569.52179',
			cy: '313.74692',
			rx: '24.399466',
			ry: '24.169281',
		},
		{
			id: 115,
			cx: '569.00494',
			cy: '252.94421',
			rx: '23.406664',
			ry: '23.185846',
		},
		{
			id: 10,
			cx: '630.96625',
			cy: '12.359192',
			rx: '31.106043',
			ry: '70.764877',
			transform: 'matrix(0.6863938,0.72723006,-0.7012091,0.71295567,0,0)',
		},
		{
			id: 11,
			cx: '177.02632',
			cy: '821.34314',
			rx: '30.842134',
			ry: '70.164482',
			transform: 'matrix(0.73866697,-0.67407055,0.70106614,0.71309626,0,0)',
		},
		{
			id: 14,
			cx: '565.96204',
			cy: '421.81827',
			rx: '25.493109',
			ry: '62.769684',
			transform: 'matrix(0.92802524,0.37251732,-0.3388839,0.9408282,0,0)',
		},
		{
			id: 15,
			cx: '504.15494',
			cy: '810.08258',
			rx: '25.160208',
			ry: '61.950008',
			transform: 'matrix(0.95250897,-0.30451054,0.33858651,0.94093527,0,0)',
		},
		{
			id: 18,
			cx: '1435.9022',
			cy: '11.193835',
			rx: '28.159817',
			ry: '10.887867',
			transform: 'matrix(0.80416768,0.59440251,-0.43012172,0.9027709,0,0)',
		},
		{
			id: 19,
			cx: '-646.45184',
			cy: '-1769.3433',
			rx: '28.163902',
			ry: '10.889446',
			transform: 'matrix(-0.82193919,0.56957526,-0.71588015,-0.69822318,0,0)',
		},
		{
			id: 20,
			cx: '670.23444',
			cy: '518.85352',
			rx: '21.945839',
			ry: '62.428455',
			transform: 'rotate(31.978485)',
		},
		{
			id: 21,
			cx: '287.96112',
			cy: '1113.5438',
			rx: '22.498281',
			ry: '63.999966',
			transform: 'rotate(-32.248156)',
		},
		{
			id: 24,
			cx: '1627.9615',
			cy: '1494.6316',
			rx: '8.0229988',
			ry: '23.280283',
			transform: 'matrix(0.95385191,-0.30027742,0.16946028,0.98553702,0,0)',
		},
		{
			id: 26,
			cx: '1674.4663',
			cy: '1822.2825',
			rx: '8.6355734',
			ry: '31.516588',
			transform: 'matrix(0.87953051,-0.47584251,0.19549019,0.98070566,0,0)',
		},
		{
			id: 30,
			cx: '1491.8716',
			cy: '1919.8606',
			rx: '9.5781507',
			ry: '34.431194',
			transform: 'matrix(0.83220223,-0.55447222,0.31818552,0.94802847,0,0)',
		},
		{
			id: 28,
			cx: '1406.1583',
			cy: '1944.0549',
			rx: '8.8123217',
			ry: '31.301567',
			transform: 'matrix(0.81206357,-0.58356898,0.37519326,0.92694661,0,0)',
		},
		{
			id: 25,
			cx: '1268.6122',
			cy: '753.90045',
			rx: '6.6534209',
			ry: '24.202238',
			transform: 'matrix(0.98250282,0.18624771,-0.1392702,0.99025442,0,0)',
		},
		{
			id: 27,
			cx: '1332.8583',
			cy: '552.09711',
			rx: '8.0408583',
			ry: '31.783506',
			transform: 'matrix(0.93929271,0.3431169,-0.24186863,0.97030901,0,0)',
		},
		{
			id: 31,
			cx: '1369.0767',
			cy: '424.20978',
			rx: '9.1712933',
			ry: '36.430317',
			transform: 'matrix(0.90232392,0.43105863,-0.32935853,0.94420493,0,0)',
		},
		{
			id: 29,
			cx: '1369.3551',
			cy: '355.01987',
			rx: '8.6457052',
			ry: '34.561028',
			transform: 'matrix(0.88128157,0.47259157,-0.3667276,0.93032836,0,0)',
		},
		{
			id: 32,
			cx: '1425.662',
			cy: '112.17239',
			rx: '8.9249945',
			ry: '28.518713',
			transform: 'matrix(0.80601059,0.59190111,-0.71508239,0.69904019,0,0)',
		},
		{
			id: 33,
			cx: '554.91669',
			cy: '2110.2817',
			rx: '8.8722916',
			ry: '28.350307',
			transform: 'matrix(0.52878611,-0.84875512,0.75147844,0.65975765,0,0)',
		},
		{
			id: 34,
			cx: '565.65784',
			cy: '818.84918',
			rx: '27.765661',
			ry: '90.956474',
		},
		{
			id: 35,
			cx: '564.8595',
			cy: '622.64716',
			rx: '23.551699',
			ry: '86.897644',
		},
		{
			id: 36,
			cx: '490.73788',
			cy: '642.91248',
			rx: '30.826977',
			ry: '77.443367',
		},
		{
			id: 37,
			cx: '633.51257',
			cy: '646.77814',
			rx: '30.592867',
			ry: '76.85524',
		},
		{
			id: 38,
			cx: '481.61203',
			cy: '831.58392',
			rx: '27.586645',
			ry: '69.303024',
		},
		{
			id: 39,
			cx: '649.28851',
			cy: '826.95776',
			rx: '27.255566',
			ry: '68.471283',
		},
		{
			id: 40,
			cx: '475.19708',
			cy: '987.50226',
			rx: '40.364391',
			ry: '73.858253',
		},
		{
			id: 41,
			cx: '665.69891',
			cy: '986.11676',
			rx: '38.92458',
			ry: '71.223701',
		},
		{
			id: 43,
			cx: '652.99683',
			cy: '1190.6674',
			rx: '37.11887',
			ry: '95.722656',
		},
		{
			id: 44,
			cx: '474.43875',
			cy: '1190.9581',
			rx: '36.158924',
			ry: '92.979469',
		},
		{
			id: 47,
			cx: '621.64062',
			cy: '1432.4661',
			rx: '30.63797',
			ry: '78.509804',
			transform: 'rotate(6.0379654)',
		},
		{
			id: 48,
			cx: '525.28717',
			cy: '1542.2905',
			rx: '30.63797',
			ry: '78.509804',
			transform: 'rotate(-5.3268625)',
		},
		{
			id: 49,
			cx: '467.42697',
			cy: '1644.5061',
			rx: '18.191294',
			ry: '57.446194',
		},
		{
			id: 50,
			cx: '773.49695',
			cy: '1588.765',
			rx: '18.191294',
			ry: '57.446194',
			transform: 'rotate(3.7876893)',
		},
		{
			id: 53,
			cx: '886.26404',
			cy: '2196.595',
			rx: '9.8183641',
			ry: '21.458033',
			transform: 'matrix(0.9459858,-0.32420805,0.3411803,0.93999787,0,0)',
		},
		{
			id: 54,
			cx: '1956.7377',
			cy: '1060.897',
			rx: '10.128694',
			ry: '22.136257',
			transform: 'matrix(0.91091913,0.41258495,-0.39612446,0.91819683,0,0)',
		},
		{
			id: 55,
			cx: '789.85553',
			cy: '2534.5183',
			rx: '6.8298187',
			ry: '18.821608',
			transform: 'matrix(0.7329454,-0.68028747,0.40643493,0.91367973,0,0)',
		},
		{
			id: 56,
			cx: '1873.1532',
			cy: '1941.0485',
			rx: '7.3012409',
			ry: '20.120752',
			transform: 'matrix(0.99897334,-0.04530197,-0.28047458,0.95986145,0,0)',
		},
		{
			id: 57,
			cx: '804.28351',
			cy: '2592.0862',
			rx: '5.6760311',
			ry: '17.038946',
			transform: 'matrix(0.68546125,-0.72810911,0.41329041,0.91059927,0,0)',
		},
		{
			id: 58,
			cx: '2170.8713',
			cy: '2544.9111',
			rx: '6.9311876',
			ry: '21.260946',
			transform: 'matrix(0.95295439,-0.30311372,-0.28603483,0.95821922,0,0)',
		},
		{
			id: 59,
			cx: '823.37274',
			cy: '2552.1533',
			rx: '5.4382672',
			ry: '16.049078',
			transform: 'matrix(0.73156906,-0.68176734,0.4046177,0.91448593,0,0)',
		},
		{
			id: 60,
			cx: '1878.0586',
			cy: '2404.072',
			rx: '6.265439',
			ry: '19.066788',
			transform: 'matrix(0.95029384,-0.31135449,-0.20051533,0.97969056,0,0)',
		},
		{
			id: 61,
			cx: '944.25531',
			cy: '2255.0813',
			rx: '4.8594446',
			ry: '13.589009',
			transform: 'matrix(0.92816866,-0.37215984,0.34106498,0.94003972,0,0)',
		},
		{
			id: 62,
			cx: '1772.2653',
			cy: '1323.7671',
			rx: '5.149406',
			ry: '14.141601',
			transform: 'matrix(0.957938,0.28697523,-0.28971333,0.95711347,0,0)',
		},
		{
			id: 63,
			cx: '859.35864',
			cy: '2178.4233',
			rx: '18.77949',
			ry: '30.352892',
			transform: 'rotate(-21.190107)',
		},
		{
			id: 64,
			cx: '1906.0333',
			cy: '1088.2012',
			rx: '18.791622',
			ry: '30.372499',
			transform: 'rotate(22.042808)',
		},
		{
			id: 65,
			cx: '1571.1129',
			cy: '1653.7076',
			rx: '25.435068',
			ry: '14.657498',
		},
		{
			id: 66,
			cx: '1375.6384',
			cy: '1653.041',
			rx: '24.96685',
			ry: '14.387678',
		},
		{
			id: 67,
			cx: '1502.6104',
			cy: '1542.6257',
			rx: '28.500275',
			ry: '124.21041',
			transform: 'matrix(0.99896909,-0.0453956,0.0455454,0.99896227,0,0)',
		},
		{
			id: 68,
			cx: '1317.3511',
			cy: '1531.7731',
			rx: '28.960989',
			ry: '126.21831',
			transform: 'matrix(0.99935527,-0.03590319,0.03605305,0.99934988,0,0)',
		},
		{
			id: 71,
			cx: '1570.8715',
			cy: '1115.4418',
			rx: '36.955807',
			ry: '108.71883',
		},
		{
			id: 72,
			cx: '1378.1685',
			cy: '1116.4379',
			rx: '38.360462',
			ry: '112.40508',
		},
		{
			id: 74,
			cx: '1575.5884',
			cy: '931.1983',
			rx: '33.265812',
			ry: '39.241703',
		},
		{
			id: 75,
			cx: '1385.999',
			cy: '935.92816',
			rx: '35.606899',
			ry: '42.003349',
		},
		{
			id: 78,
			cx: '1470.5271',
			cy: '150.53972',
			rx: '43.624439',
			ry: '15.247375',
		},
		{
			id: 81,
			cx: '1506.8395',
			cy: '234.46761',
			rx: '18.654322',
			ry: '12.156749',
		},
		{
			id: 82,
			cx: '1442.4596',
			cy: '234.3429',
			rx: '17.746706',
			ry: '11.565269',
		},
		{
			id: 83,
			cx: '1551.9313',
			cy: '-238.6339',
			rx: '9.9225178',
			ry: '27.096107',
			transform: 'rotate(18.257208)',
		},
		{
			id: 84,
			cx: '1280.5122',
			cy: '625.73297',
			rx: '9.8838387',
			ry: '26.990482',
			transform: 'rotate(-15.512505)',
		},
		{
			id: 85,
			cx: '1475.1733',
			cy: '297.81351',
			rx: '22.888371',
			ry: '11.97238',
		},
		{
			id: 86,
			cx: '1476.1113',
			cy: '248.54764',
			rx: '11.311793',
			ry: '24.935865',
		},
		{
			id: 89,
			cx: '1475.3848',
			cy: '339.44769',
			rx: '17.90044',
			ry: '14.121459',
		},
		{
			id: 90,
			cx: '1473.9784',
			cy: '385.50467',
			rx: '40.05785',
			ry: '22.379738',
		},
		{
			id: 91,
			cx: '999.73303',
			cy: '1300.4045',
			rx: '31.981167',
			ry: '61.343403',
			transform: 'matrix(0.82357641,-0.56720534,0.61248899,0.79047912,0,0)',
		},
		{
			id: 92,
			cx: '1354.7997',
			cy: '-459.03067',
			rx: '31.205933',
			ry: '59.856415',
			transform: 'matrix(0.78599778,0.61822931,-0.57318789,0.81942397,0,0)',
		},
		{
			id: 93,
			cx: '1469.0865',
			cy: '430.70132',
			rx: '66.011711',
			ry: '16.775251',
		},
		{
			id: 96,
			cx: '1471.4285',
			cy: '590.25543',
			rx: '29.593233',
			ry: '83.813004',
		},
		{
			id: 97,
			cx: '1413.3149',
			cy: '1103.9219',
			rx: '23.765991',
			ry: '58.006042',
			transform: 'matrix(0.94786403,-0.31867504,0.28910902,0.95729618,0,0)',
		},
		{
			id: 98,
			cx: '1416.4133',
			cy: '178.32654',
			rx: '23.690445',
			ry: '57.821659',
			transform: 'matrix(0.94956561,0.31356842,-0.34288278,0.9393782,0,0)',
		},
		{
			id: 99,
			cx: '1475.8353',
			cy: '789.20123',
			rx: '44.229942',
			ry: '72.758255',
		},
		{
			id: 102,
			cx: '1566.4503',
			cy: '802.98657',
			rx: '28.946764',
			ry: '68.419624',
		},
		{
			id: 103,
			cx: '1389.6774',
			cy: '805.56812',
			rx: '27.622444',
			ry: '65.289413',
		},
		{
			id: 104,
			cx: '1472.2866',
			cy: '475.58835',
			rx: '90.208534',
			ry: '21.10383',
		},
		{
			id: 105,
			cx: '1397.2479',
			cy: '610.47156',
			rx: '32.196777',
			ry: '60.605701',
		},
		{
			id: 106,
			cx: '1548.7565',
			cy: '604.09174',
			rx: '31.751888',
			ry: '59.768261',
		},
		{
			id: 107,
			cx: '64.378235',
			cy: '1255.8524',
			rx: '30.964767',
			ry: '10.940261',
			transform: 'matrix(0.77797188,-0.6282991,0.67646474,0.73647502,0,0)',
		},
		{
			id: 108,
			cx: '702.26282',
			cy: '534.33484',
			rx: '30.515429',
			ry: '10.781504',
			transform: 'matrix(0.77414431,0.63300915,-0.58253768,0.8128037,0,0)',
		},
		{
			id: 109,
			cx: '955.01953',
			cy: '1654.1957',
			rx: '23.992657',
			ry: '54.050762',
			transform: 'matrix(0.80288881,-0.59612882,0.58466042,0.81127812,0,0)',
		},
		{
			id: 110,
			cx: '1437.5165',
			cy: '79.341866',
			rx: '24.414955',
			ry: '55.002117',
			transform: 'matrix(0.86587308,0.50026375,-0.51251641,0.85867743,0,0)',
		},
	],
};
