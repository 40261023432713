// prettier-ignore
export default {
	circles: [
		{
			id: 8,
			cx: '596.22614',
			cy: '433.46268',
			r: '20.503029',
		},
		{
			id: 7,
			cx: '531.88892',
			cy: '432.53668',
			r: '21.439465',
		},
		{
			id: 9,
			cx: '564.11279',
			cy: '524.18341',
			r: '42.739182',
		},
		{
			id: 16,
			cx: '335.63229',
			cy: '778.99097',
			r: '24.403927',
		},
		{
			id: 17,
			cx: '786.79388',
			cy: '778.75806',
			r: '25.106253',
		},
		{
			id: 12,
			cx: '1339.0614',
			cy: '653.68774',
			r: '23.517534',
		},
		{
			id: 13,
			cx: '1613.702',
			cy: '648.57825',
			r: '21.862135',
		},
		{
			id: 22,
			cx: '195.28554',
			cy: '1007.8171',
			r: '27.007568',
		},
		{
			id: 23,
			cx: '919.84985',
			cy: '1009.6898',
			r: '28.412222',
		},
		{
			id: 42,
			cx: '550.22546',
			cy: '1067.6737',
			r: '31.45219',
		},
		{
			id: 45,
			cx: '447.86026',
			cy: '1385.2739',
			r: '29.537319',
		},
		{
			id: 46,
			cx: '624.89264',
			cy: '1391.4053',
			r: '28.828098',
		},
		{
			id: 51,
			cx: '420.07227',
			cy: '1814.6606',
			r: '27.615551',
		},
		{
			id: 52,
			cx: '621.35687',
			cy: '1823.5911',
			r: '27.849661',
		},
		{
			id: 69,
			cx: '1589.4343',
			cy: '1387.2059',
			r: '30.066895',
		},
		{
			id: 70,
			cx: '1416.4385',
			cy: '1382.0555',
			r: '31.47155',
		},
		{
			id: 73,
			cx: '1484.3262',
			cy: '1078.6636',
			r: '28.630741',
		},
		{
			id: 76,
			cx: '1510.1249',
			cy: '274.8067',
			r: '13.352617',
		},
		{
			id: 77,
			cx: '1445.9139',
			cy: '275.4689',
			r: '14.014777',
		},
		{
			id: 79,
			cx: '1534.8593',
			cy: '288.59341',
			r: '10.129114',
		},
		{
			id: 80,
			cx: '1420.1863',
			cy: '289.58664',
			r: '10.460195',
		},
		{
			id: 87,
			cx: '1514.7732',
			cy: '381.10034',
			r: '13.79553',
		},
		{
			id: 88,
			cx: '1438.2141',
			cy: '380.76926',
			r: '14.12661',
		},
		{
			id: 95,
			cx: '1509.3931',
			cy: '427.58749',
			r: '11.860545',
		},
		{
			id: 94,
			cx: '1440.2434',
			cy: '425.9321',
			r: '12.191626',
		},
		{
			id: 100,
			cx: '1704.8239',
			cy: '804.61353',
			r: '22.448265',
		},
		{
			id: 101,
			cx: '1250.481',
			cy: '807.22192',
			r: '23.772585',
		},
		{
			id: 111,
			cx: '1828.5847',
			cy: '1016.9059',
			r: '23.328659',
		},
		{
			id: 112,
			cx: '1126.4941',
			cy: '1018.8219',
			r: '24.937002',
		},
	],
	ellipses: [
		{
			id: 1,
			cx: '558.73462',
			cy: '216.29779',
			rx: '63.375931',
			ry: '17.06275',
		},
		{
			id: 2,
			cx: '515.88684',
			cy: '264.74704',
			rx: '23.593699',
			ry: '24.354786',
		},
		{
			id: 3,
			cx: '609.24084',
			cy: '264.04047',
			rx: '23.783913',
			ry: '24.551134',
		},
		{
			id: 5,
			cx: '354.41879',
			cy: '438.39636',
			rx: '14.692904',
			ry: '29.312397',
			transform: 'matrix(0.97165925,-0.23638591,0.31100489,0.95040831,0,0)',
		},
		{
			id: 6,
			cx: '717.55347',
			cy: '57.06686',
			rx: '13.786596',
			ry: '27.504311',
			transform: 'matrix(0.92307939,0.38460947,-0.3118877,0.95011897,0,0)',
		},
		{
			id: 4,
			cx: '564.8396',
			cy: '374.61526',
			rx: '24.399466',
			ry: '24.169281',
		},
		{
			id: 115,
			cx: '564.32275',
			cy: '312.87613',
			rx: '23.406664',
			ry: '23.185846',
		},
		{
			id: 10,
			cx: '674.16229',
			cy: '67.997223',
			rx: '33.262955',
			ry: '75.671753',
			transform: 'matrix(0.6863938,0.72723006,-0.7012091,0.71295567,0,0)',
		},
		{
			id: 11,
			cx: '261.47327',
			cy: '845.03461',
			rx: '30.842134',
			ry: '70.164482',
			transform: 'matrix(0.83292525,-0.55338552,0.5839688,0.8117761,0,0)',
		},
		{
			id: 14,
			cx: '575.42615',
			cy: '494.71152',
			rx: '25.493109',
			ry: '62.769684',
			transform: 'matrix(0.92802524,0.37251732,-0.3388839,0.9408282,0,0)',
		},
		{
			id: 15,
			cx: '473.30609',
			cy: '874.74048',
			rx: '25.160208',
			ry: '61.950008',
			transform: 'matrix(0.95250897,-0.30451054,0.33858651,0.94093527,0,0)',
		},
		{
			id: 18,
			cx: '1483.4534',
			cy: '80.502457',
			rx: '28.159817',
			ry: '10.887867',
			transform: 'matrix(0.80416768,0.59440251,-0.43012172,0.9027709,0,0)',
		},
		{
			id: 19,
			cx: '-580.79132',
			cy: '-1852.5801',
			rx: '28.163902',
			ry: '10.889446',
			transform: 'matrix(-0.82193919,0.56957526,-0.71588015,-0.69822318,0,0)',
		},
		{
			id: 20,
			cx: '699.57898',
			cy: '597.68134',
			rx: '21.945839',
			ry: '62.428455',
			transform: 'rotate(31.978485)',
		},
		{
			id: 21,
			cx: '242.0764',
			cy: '1189.7819',
			rx: '22.498281',
			ry: '63.999966',
			transform: 'rotate(-32.248156)',
		},
		{
			id: 24,
			cx: '1614.2906',
			cy: '1582.6337',
			rx: '8.0229988',
			ry: '23.280283',
			transform: 'matrix(0.95385191,-0.30027742,0.16946028,0.98553702,0,0)',
		},
		{
			id: 26,
			cx: '1518.4464',
			cy: '2006.5382',
			rx: '8.6355734',
			ry: '31.516588',
			transform: 'matrix(0.83319337,-0.55298175,0.28285971,0.95916129,0,0)',
		},
		{
			id: 30,
			cx: '1424.6107',
			cy: '2024.6249',
			rx: '9.5781507',
			ry: '34.431194',
			transform: 'matrix(0.81875829,-0.57413837,0.34067775,0.94018013,0,0)',
		},
		{
			id: 28,
			cx: '1372.51',
			cy: '2021.8746',
			rx: '8.8123217',
			ry: '31.301567',
			transform: 'matrix(0.81206358,-0.58356898,0.37519326,0.92694661,0,0)',
		},
		{
			id: 25,
			cx: '1286.5122',
			cy: '842.14258',
			rx: '6.6534209',
			ry: '24.202238',
			transform: 'matrix(0.98250282,0.18624771,-0.1392702,0.99025442,0,0)',
		},
		{
			id: 27,
			cx: '1341.2755',
			cy: '673.9541',
			rx: '8.0408583',
			ry: '31.783506',
			transform: 'matrix(0.94738673,0.32009122,-0.21811615,0.97592282,0,0)',
		},
		{
			id: 31,
			cx: '1393.6589',
			cy: '529.22437',
			rx: '9.1712933',
			ry: '36.430317',
			transform: 'matrix(0.90919574,0.41636895,-0.31400304,0.94942198,0,0)',
		},
		{
			id: 29,
			cx: '1408.9224',
			cy: '433.85349',
			rx: '8.6457052',
			ry: '34.561028',
			transform: 'matrix(0.88128157,0.47259157,-0.3667276,0.93032836,0,0)',
		},
		{
			id: 32,
			cx: '1496.6312',
			cy: '187.53595',
			rx: '8.9249945',
			ry: '28.518713',
			transform: 'matrix(0.80601059,0.59190111,-0.71508239,0.69904019,0,0)',
		},
		{
			id: 33,
			cx: '481.36972',
			cy: '2163.28',
			rx: '8.8722916',
			ry: '28.350307',
			transform: 'matrix(0.52878611,-0.84875512,0.75147844,0.65975765,0,0)',
		},
		{
			id: 34,
			cx: '564.72137',
			cy: '878.78107',
			rx: '27.765661',
			ry: '90.956474',
		},
		{
			id: 35,
			cx: '562.98663',
			cy: '676.02405',
			rx: '23.551699',
			ry: '86.897644',
		},
		{
			id: 36,
			cx: '488.86502',
			cy: '693.48004',
			rx: '30.826977',
			ry: '77.443367',
		},
		{
			id: 37,
			cx: '631.63971',
			cy: '695.47284',
			rx: '30.592867',
			ry: '76.85524',
		},
		{
			id: 38,
			cx: '478.8027',
			cy: '884.96082',
			rx: '27.586645',
			ry: '69.303024',
		},
		{
			id: 39,
			cx: '646.47919',
			cy: '883.14392',
			rx: '27.255566',
			ry: '68.471283',
		},
		{
			id: 40,
			cx: '456.46835',
			cy: '1045.5613',
			rx: '40.364391',
			ry: '73.858253',
		},
		{
			id: 41,
			cx: '654.46167',
			cy: '1048.858',
			rx: '38.92458',
			ry: '71.223701',
		},
		{
			id: 43,
			cx: '636.14099',
			cy: '1243.1078',
			rx: '37.11887',
			ry: '95.722656',
		},
		{
			id: 44,
			cx: '451.02783',
			cy: '1236.8435',
			rx: '36.158924',
			ry: '92.979469',
		},
		{
			id: 47,
			cx: '592.25299',
			cy: '1475.1243',
			rx: '30.63797',
			ry: '78.509804',
			transform: 'rotate(6.0379654)',
		},
		{
			id: 48,
			cx: '488.04581',
			cy: '1588.6646',
			rx: '30.63797',
			ry: '78.509804',
			transform: 'rotate(-5.3268625)',
		},
		{
			id: 49,
			cx: '426.22379',
			cy: '1684.7728',
			rx: '18.191294',
			ry: '57.446194',
		},
		{
			id: 50,
			cx: '734.04102',
			cy: '1644.8708',
			rx: '18.191294',
			ry: '57.446194',
			transform: 'rotate(3.7876893)',
		},
		{
			id: 53,
			cx: '815.47131',
			cy: '2303.3784',
			rx: '9.8183641',
			ry: '21.458033',
			transform: 'matrix(0.93503569,-0.35455361,0.37132388,0.92850341,0,0)',
		},
		{
			id: 54,
			cx: '2165.282',
			cy: '788.16382',
			rx: '10.128694',
			ry: '22.136257',
			transform: 'matrix(0.83647222,0.54800932,-0.53286671,0.84619919,0,0)',
		},
		{
			id: 55,
			cx: '787.21716',
			cy: '2608.397',
			rx: '6.8298187',
			ry: '18.821608',
			transform: 'matrix(0.7329454,-0.68028747,0.40643493,0.91367973,0,0)',
		},
		{
			id: 56,
			cx: '1940.6821',
			cy: '2021.3076',
			rx: '7.3012409',
			ry: '20.120752',
			transform: 'matrix(0.99897334,-0.04530197,-0.28047458,0.95986145,0,0)',
		},
		{
			id: 57,
			cx: '800.1413',
			cy: '2666.9304',
			rx: '5.6760311',
			ry: '17.038946',
			transform: 'matrix(0.68546125,-0.72810911,0.41329041,0.91059927,0,0)',
		},
		{
			id: 58,
			cx: '2215.5979',
			cy: '2628.4456',
			rx: '6.9311876',
			ry: '21.260946',
			transform: 'matrix(0.95295439,-0.30311372,-0.28603483,0.95821922,0,0)',
		},
		{
			id: 59,
			cx: '820.08594',
			cy: '2627.5271',
			rx: '5.4382672',
			ry: '16.049078',
			transform: 'matrix(0.73156906,-0.68176734,0.4046177,0.91448593,0,0)',
		},
		{
			id: 60,
			cx: '1946.0532',
			cy: '2502.1492',
			rx: '6.265439',
			ry: '19.066788',
			transform: 'matrix(0.95029384,-0.31135449,-0.20051533,0.97969056,0,0)',
		},
		{
			id: 61,
			cx: '946.63342',
			cy: '2333.7239',
			rx: '4.8594446',
			ry: '13.589009',
			transform: 'matrix(0.92816866,-0.37215984,0.34106498,0.94003972,0,0)',
		},
		{
			id: 62,
			cx: '1811.2456',
			cy: '1381.5455',
			rx: '5.149406',
			ry: '14.141601',
			transform: 'matrix(0.957938,0.28697523,-0.28971333,0.95711347,0,0)',
		},
		{
			id: 63,
			cx: '868.41608',
			cy: '2261.2778',
			rx: '18.77949',
			ry: '30.352892',
			transform: 'rotate(-21.190107)',
		},
		{
			id: 64,
			cx: '1966.4294',
			cy: '1142.5492',
			rx: '18.791622',
			ry: '30.372499',
			transform: 'rotate(22.042808)',
		},
		{
			id: 65,
			cx: '1613.2526',
			cy: '1743.6055',
			rx: '25.435068',
			ry: '14.657498',
		},
		{
			id: 66,
			cx: '1412.1594',
			cy: '1740.1296',
			rx: '24.96685',
			ry: '14.387678',
		},
		{
			id: 67,
			cx: '1532.531',
			cy: '1647.1003',
			rx: '28.500275',
			ry: '124.21041',
			transform: 'matrix(0.99896909,-0.0453956,0.0455454,0.99896227,0,0)',
		},
		{
			id: 68,
			cx: '1349.0057',
			cy: '1615.3702',
			rx: '28.960989',
			ry: '126.21831',
			transform: 'matrix(0.99935527,-0.03590319,0.03605305,0.99934988,0,0)',
		},
		{
			id: 71,
			cx: '1590.5366',
			cy: '1222.1956',
			rx: '36.955807',
			ry: '108.71883',
		},
		{
			id: 72,
			cx: '1395.9607',
			cy: '1221.3187',
			rx: '38.360462',
			ry: '112.40508',
		},
		{
			id: 74,
			cx: '1597.1265',
			cy: '1034.2063',
			rx: '33.265812',
			ry: '39.241703',
		},
		{
			id: 75,
			cx: '1391.6177',
			cy: '1038.9362',
			rx: '35.606899',
			ry: '42.003349',
		},
		{
			id: 78,
			cx: '1478.0186',
			cy: '235.75542',
			rx: '43.624439',
			ry: '15.247375',
		},
		{
			id: 81,
			cx: '1507.7759',
			cy: '336.53915',
			rx: '18.654322',
			ry: '12.156749',
		},
		{
			id: 82,
			cx: '1445.2689',
			cy: '334.54156',
			rx: '17.746706',
			ry: '11.565269',
		},
		{
			id: 83,
			cx: '1584.8254',
			cy: '-150.87781',
			rx: '9.9225178',
			ry: '27.096107',
			transform: 'rotate(18.257208)',
		},
		{
			id: 84,
			cx: '1251.858',
			cy: '714.96356',
			rx: '9.8838387',
			ry: '26.990482',
			transform: 'rotate(-15.512505)',
		},
		{
			id: 85,
			cx: '1474.2369',
			cy: '405.50366',
			rx: '22.888371',
			ry: '11.97238',
		},
		{
			id: 86,
			cx: '1475.7734',
			cy: '359.04712',
			rx: '10.037392',
			ry: '19.317247',
		},
		{
			id: 89,
			cx: '1473.5118',
			cy: '440.58279',
			rx: '17.90044',
			ry: '14.121459',
		},
		{
			id: 90,
			cx: '1477.7241',
			cy: '482.89404',
			rx: '40.05785',
			ry: '22.379738',
		},
		{
			id: 91,
			cx: '940.12128',
			cy: '1380.5607',
			rx: '35.897285',
			ry: '68.85495',
			transform: 'matrix(0.82357641,-0.56720534,0.61248899,0.79047912,0,0)',
		},
		{
			id: 92,
			cx: '1416.8634',
			cy: '-378.00858',
			rx: '37.788349',
			ry: '72.482216',
			transform: 'matrix(0.78599778,0.61822931,-0.57318789,0.81942397,0,0)',
		},
		{
			id: 93,
			cx: '1483.1331',
			cy: '529.96356',
			rx: '66.011711',
			ry: '16.775251',
		},
		{
			id: 96,
			cx: '1477.9835',
			cy: '703.56421',
			rx: '29.593233',
			ry: '83.813004',
		},
		{
			id: 97,
			cx: '1391.3875',
			cy: '1217.9204',
			rx: '23.765991',
			ry: '58.006042',
			transform: 'matrix(0.94786403,-0.31867504,0.28910902,0.95729618,0,0)',
		},
		{
			id: 98,
			cx: '1455.6049',
			cy: '286.86212',
			rx: '23.690445',
			ry: '57.821659',
			transform: 'matrix(0.94956561,0.31356842,-0.34288278,0.9393782,0,0)',
		},
		{
			id: 99,
			cx: '1477.7083',
			cy: '886.59058',
			rx: '44.229942',
			ry: '72.758255',
		},
		{
			id: 102,
			cx: '1569.2596',
			cy: '902.24878',
			rx: '28.946764',
			ry: '68.419624',
		},
		{
			id: 103,
			cx: '1384.0587',
			cy: '906.70325',
			rx: '27.622444',
			ry: '65.289413',
		},
		{
			id: 104,
			cx: '1484.4603',
			cy: '576.72345',
			rx: '90.208534',
			ry: '21.10383',
		},
		{
			id: 105,
			cx: '1392.5658',
			cy: '725.6532',
			rx: '32.196777',
			ry: '60.605701',
		},
		{
			id: 106,
			cx: '1557.1844',
			cy: '725.82843',
			rx: '31.751888',
			ry: '59.768261',
		},
		{
			id: 107,
			cx: '10.368275',
			cy: '1316.5825',
			rx: '30.964767',
			ry: '10.940261',
			transform: 'matrix(0.77797188,-0.6282991,0.67646474,0.73647502,0,0)',
		},
		{
			id: 108,
			cx: '600.14172',
			cy: '741.24103',
			rx: '30.515429',
			ry: '10.781504',
			transform: 'matrix(0.88190985,0.47141809,-0.41442534,0.91008331,0,0)',
		},
		{
			id: 109,
			cx: '899.86108',
			cy: '1747.5608',
			rx: '23.992657',
			ry: '54.050762',
			transform: 'matrix(0.80288881,-0.59612882,0.58466042,0.81127812,0,0)',
		},
		{
			id: 110,
			cx: '1486.7993',
			cy: '160.77594',
			rx: '24.414955',
			ry: '55.002117',
			transform: 'matrix(0.86587308,0.50026375,-0.51251641,0.85867743,0,0)',
		},
	],
};
