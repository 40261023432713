import React, { useEffect, useRef, useState } from "react";


import { BodyPart } from "../../helpers/types";
import { isNotNullAndUndefined } from "../../helpers/utils";
import "./style.css";
interface GroupedBodyPartsObj {
  selectedCount: number;
  parts: number[];
}

interface BodyPartSidebarProps {
  bodyParts: Map<number, { selected: boolean; data: BodyPart; }>;
  handleBodyPartClick: (number) => void;
}

const BodyPartSidebar: React.FC<BodyPartSidebarProps> = ({
  bodyParts,
  handleBodyPartClick,
}) => {
  const [visibleGroups, setVisibleGroups] = useState<{
    [key: string]: boolean;
  }>(null);
  const [searchTerm, setSearchTerm] = useState<string>(null);
  const [groupedBodyParts, setGroupedBodyParts] = useState<
    Map<string, GroupedBodyPartsObj>
  >(new Map<string, GroupedBodyPartsObj>());

  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (groupedBodyParts.size === 0) {
      const bodyPartGroupMap = new Map<string, GroupedBodyPartsObj>();
      let visibleGroupObj = {};

      bodyParts.forEach((bodyPartData, bodyPartId) => {
        const groupName = bodyPartData.data.displayGroupName;

        if (bodyPartGroupMap.has(groupName)) {
          bodyPartGroupMap.get(groupName).parts.push(bodyPartId);
        } else {
          bodyPartGroupMap.set(groupName, {
            selectedCount: 0,
            parts: [bodyPartId],
          });
          visibleGroupObj[groupName] = false;
        }

        if (bodyPartData.selected)
          bodyPartGroupMap.get(groupName).selectedCount++;
      });

      setGroupedBodyParts(bodyPartGroupMap);
      setVisibleGroups(visibleGroupObj);
    } else {
      const bodyPartGroupMap = new Map<string, GroupedBodyPartsObj>(
        groupedBodyParts
      );

      bodyPartGroupMap.forEach((groupData) => {
        groupData.selectedCount = 0;

        groupData.parts.forEach((bodyPartId) => {
          if (bodyParts.get(bodyPartId).selected) groupData.selectedCount++;
        });
      });

      setGroupedBodyParts(bodyPartGroupMap);
    }
  }, [bodyParts]);

  const handleToggleVisibility = (groupName: string) => {
    let updatedVisibleGroup = { ...visibleGroups };
    updatedVisibleGroup[groupName] = !updatedVisibleGroup[groupName];

    setVisibleGroups(updatedVisibleGroup);
  };

  const handleSearchTermUpdate = (str: string) => {
    if (str === "") {
      if (searchTerm !== null) {
        setSearchTerm(null);
      }
    } else {
      setSearchTerm(str);
    }
  };

  const bodyPartElement = (bodyPart: BodyPart) => {
    return (
      <div
        key={bodyPart.id}
        className="body-part-picker__sidebar__toggle-section__body-part"
      // onMouseEnter={() => setBodyPartHover(bodyPartData.id)}
      // onMouseLeave={() => setBodyPartHover(null)}
      >
        <input
          type="checkbox"
          className="body-part-picker__sidebar__toggle-section__body-part__checkbox"
          checked={bodyParts.get(bodyPart.id).selected}
          id={"buttonBodyPartPickerSidebarCheckBox_" + bodyPart.id}
          onChange={() => {
            handleBodyPartClick(bodyPart.id);
          }}
        />
        {bodyPart.displayName}
      </div>
    );
  };

  const groupedBodyPartElements = () => {
    let elements: JSX.Element[] = [];

    groupedBodyParts.forEach((groupData, groupName) => {
      const isGroupVisible = visibleGroups[groupName];

      elements.push(
        <div key={groupName}>
          <div className="body-part-picker__sidebar__header">
            <button
              className="body-part-picker__sidebar__header__chevron"
              id={"buttonBodyPartPickerSidebarHeader_" + groupName}
              onClick={() => {
                handleToggleVisibility(groupName);
              }}
            >
              {isGroupVisible ? "⯆" : "⯈"}
            </button>
            <span>{groupName}</span>
            <span style={{ marginLeft: "0.25rem", color: "red" }}>
              {groupData.selectedCount > 0 && `[${groupData.selectedCount}]`}
            </span>
          </div>
          {isGroupVisible && (
            <div className="body-part-picker__sidebar__toggle-section">
              {groupData.parts.map((bodyPartId) =>
                bodyPartElement(bodyParts.get(bodyPartId).data)
              )}
            </div>
          )}
        </div>
      );
    });

    return elements;
  };

  const searchedBodyPartElements = () => {
    let elements: JSX.Element[] = [];

    bodyParts.forEach((bodyPart) => {
      if (bodyPart.data.displayName.toLocaleLowerCase().includes(searchTerm)) {
        elements.push(bodyPartElement(bodyPart.data));
      }
    });

    if (elements.length === 0) {
      return (
        <div
          style={{ fontSize: "0.8rem", fontWeight: 600, textAlign: "center" }}
        >
          Sorry no body parts found
        </div>
      );
    } else {
      return elements;
    }
  };

  const init = () => {
    groupedBodyParts.forEach((groupData, groupName) => {
      const isGroupVisible = visibleGroups[groupName];

      const objElement1: HTMLElement = document.getElementById(
        "buttonBodyPartPickerSidebarHeader_" + groupName
      );
      if (isNotNullAndUndefined(objElement1)) {
        objElement1.onclick = () => {
          handleToggleVisibility(groupName);
        };
      }

      if (isGroupVisible) {
        groupData.parts.forEach((bodyPartId) => {
          const buttonBodyPartPickerSidebarCheckBox: HTMLElement =
            document.getElementById(
              "buttonBodyPartPickerSidebarCheckBox_" + bodyPartId
            );
          if (isNotNullAndUndefined(buttonBodyPartPickerSidebarCheckBox)) {
            buttonBodyPartPickerSidebarCheckBox.onchange = () => {
              handleBodyPartClick(bodyPartId);
            };
          }
        });
      }
    });
    const divBodyPartSideBarSearchClear: HTMLElement = document.getElementById(
      "divBodyPartSideBarSearchClear"
    );
    if (isNotNullAndUndefined(divBodyPartSideBarSearchClear)) {
      divBodyPartSideBarSearchClear.onclick = (e) => {
        const eTarget: HTMLElement = e.target as HTMLElement;
        //console.log('e', e.target, eTarget.closest('#buttonBodyPartSideBarClearSearch'));

        const buttonBodyPartSideBarSearch: HTMLElement = eTarget.closest(
          "#buttonBodyPartSideBarSearch"
        );
        if (isNotNullAndUndefined(buttonBodyPartSideBarSearch)) {
          handleSearchTermUpdate(searchInputRef.current.value);
        }
        const buttonBodyPartSideBarClearSearch: HTMLElement = eTarget.closest(
          "#buttonBodyPartSideBarClearSearch"
        );
        if (isNotNullAndUndefined(buttonBodyPartSideBarClearSearch)) {
          searchInputRef.current.value = "";
          handleSearchTermUpdate("");
        }
      };
    }
  };

  setTimeout(() => {
    init();
  }, 500);

  return (
    <div className="body-part-picker__sidebar">
      <div className="body-part-picker__sidebar__toolbar">
        <div className="body-part-picker__sidebar__toolbar__search">
          <input
            ref={searchInputRef}
            type="textbox"
            className="body-part-picker__sidebar__toolbar__search__input"
          />
          <div
            className="body-part-picker__sidebar__toolbar__search__btn"
            id="divBodyPartSideBarSearchClear"
          >
            {searchTerm !== null ? (
              <button
                className="body-part-picker__sidebar__toolbar__search__btn-icon input-btn"
                id="buttonBodyPartSideBarClearSearch"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="16px"
                  height="16px"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                </svg>
              </button>
            ) : null}
            {searchTerm !== null ? (
              <span style={{ marginTop: "-0.1rem" }}>|</span>
            ) : null}
            <button
              className="body-part-picker__sidebar__toolbar__search__btn-icon input-btn"
              id="buttonBodyPartSideBarSearch"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="16px"
                height="16px"
              >
                <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <hr style={{ width: "100%" }} />
      {searchTerm === null
        ? groupedBodyPartElements()
        : searchedBodyPartElements()}
    </div>
  );
};

export default BodyPartSidebar;
