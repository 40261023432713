// prettier-ignore
export default {
	circles: [
  	{       
			id: 8,
			cx: "549.77551",
			cy: "316.93536",
			r: "29.041885",
		},
		{       
			id: 7,
			cx: "473.00391",
			cy: "317.44043",
			r: "29.041885",
		},
		{       
			id: 9,
			cx: "515.64703",
			cy: "416.3562",
			r: "54.231342",
		},
		{       
			id: 16,
			cx: "246.42857",
			cy: "712.85718",
			r: "31.428572",
		},
    {       
			id: 17,
			cx: "786.89771",
			cy: "722.24438",
			r: "31.428572",
		},
    // {       
	// 		id: 12,
	// 		cx: "1348.8062",
	// 		cy: "540.17908",
	// 		r: "30.052038",
	// 	},
    // {       
	// 		id: 13,
	// 		cx: "1680.1362",
	// 		cy: "544.21967",
	// 		r: "30.052038",
	// 	},
		{       
			id: 22,
			cx: "101.07143",
			cy: "951.07141",
			r: "33.92857",
		},
    {       
			id: 23,
			cx: "926.78571",
			cy: "951.07141",
			r: "33.92857",
		},
		{       
			id: 42,
			cx: "516.18793",
			cy: "1001.0612",
			r: "37.375645",
		},
		{       
			id: 45,
			cx: "415.1727",
			cy: "1362.6958",
			r: "35.355339",
		},
    {       
			id: 46,
			cx: "611.14227",
			cy: "1362.6958",
			r: "35.355339",
		},
		{       
			id: 51,
			cx: "411.13208",
			cy: "1746.5537",
			r: "33.335033",
		},
    {       
			id: 52,
			cx: "621.24384",
			cy: "1746.5537",
			r: "33.335033",
		},
	// 	{       
	// 		id: 69,
	// 		cx: "1613.9712",
	// 		cy: "1329.6133",
	// 		r: "38.638336",
	// 	},
    // {       
	// 		id: 70,
	// 		cx: "1423.0524",
	// 		cy: "1329.6133",
	// 		r: "38.638336",
	// 	},
		// {       
		// 	id: 73,
		// 	cx: "1512.9559",
		// 	cy: "1014.9507",
		// 	r: "37.123108",
		// },
		// {       
		// 	id: 76,
		// 	cx: "1543.2606",
		// 	cy: "146.72466",
		// 	r: "17.930208",
		// },
    // {       
	// 		id: 77,
	// 		cx: "1486.6921",
	// 		cy: "146.72466",
	// 		r: "17.930208",
	// 	},
		// {       
		// 	id: 79,
		// 	cx: "1576.3431",
		// 	cy: "157.0787",
		// 	r: "13.131984",
		// },
    // {       
	// 		id: 80,
	// 		cx: "1453.6096",
	// 		cy: "157.0787",
	// 		r: "13.131984",
	// 	},
	// 	{       
	// 		id: 87,
	// 		cx: "1556.8976",
	// 		cy: "239.65869",
	// 		r: "19.445436",
	// 	},
    // {       
	// 		id: 88,
	// 		cx: "1475.0752",
	// 		cy: "239.65869",
	// 		r: "19.445436",
	// 	},
		// {       
		// 	id: 95,
		// 	cx: "1566.2416",
		// 	cy: "284.86301",
		// 	r: "15.657365",
		// },
    // {       
	// 		id: 94,
	// 		cx: "1462.1959",
	// 		cy: "284.86301",
	// 		r: "15.657365",
	// 	},
	// 	{       
	// 		id: 100,
	// 		cx: "1794.7886",
	// 		cy: "718.97607",
	// 		r: "27.526657",
	// 	},
    // {       
	// 		id: 101,
	// 		cx: "1237.1844",
	// 		cy: "715.44055",
	// 		r: "27.526657",
	// 	},
	// 	{       
	// 		id: 111,
	// 		cx: "1917.6786",
	// 		cy: "939.46429",
	// 		r: "31.25",
	// 	},
    // {       
	// 		id: 112,
	// 		cx: "1117.6378",
	// 		cy: "939.46429",
	// 		r: "31.25",
	// 	},
	],
	ellipses: [
		{
			id: 1,
			cx: '511.13718',
			cy: '95.9645',
			rx: '78.791901',
			ry: '21.213203',
		},
		{
			id: 2,
			cx: '459.5788',
			cy: '153.54318',
			rx: '31.31473',
			ry: '32.324883',
		},
		{
			id: 3,
			cx: '564.69556',
			cy: '151.52287',
			rx: '31.31473',
			ry: '32.324883',
		},
		{
			id: 5,
			cx: '335.5929',
			cy: '312.31778',
			rx: '17.430126',
			ry: '34.773167',
			transform: 'matrix(0.97165925,-0.23638591,0.31100489,0.95040831,0,0)',
		},
		{
			id: 6,
			cx: '642.43866',
			cy: '-30.38957',
			rx: '17.430126',
			ry: '34.773167',
			transform: 'matrix(0.92307939,0.38460947,-0.3118877,0.95011897,0,0)',
		},
		{
			id: 4,
			cx: '514.1391',
			cy: '258.79987',
			rx: '31.228619',
			ry: '30.934008',
		},
		{
			id: 115,
			cx: '512.65759',
			cy: '195.66534',
			rx: '27.659412',
			ry: '27.398474',
		},
		{
			id: 10,
			cx: '562.34503',
			cy: '32.662766',
			rx: '43.390835',
			ry: '98.712227',
			transform: 'matrix(0.64805204,0.76159605,-0.73697678,0.67591806,0,0)',
		},
		{
			id: 11,
			cx: '201.92462',
			cy: '789.63934',
			rx: '43.390835',
			ry: '98.712227',
			transform: 'matrix(0.75955874,-0.65043872,0.67822734,0.73485214,0,0)',
		},
		{
			id: 14,
			cx: '470.37445',
			cy: '458.70554',
			rx: '29.667603',
			ry: '73.04821',
			transform: 'matrix(0.92802524,0.37251732,-0.3388839,0.9408282,0,0)',
		},
		{
			id: 15,
			cx: '410.74927',
			cy: '855.04663',
			rx: '29.667603',
			ry: '73.04821',
			transform: 'matrix(0.91816769,-0.396192,0.42897293,0.90331734,0,0)',
		},
		// {
		// 	id: 18,
		// 	cx: '1442.7126',
		// 	cy: '15.371117',
		// 	rx: '37.366993',
		// 	ry: '14.447782',
		// 	transform: 'matrix(0.80416768,0.59440251,-0.43012172,0.9027709,0,0)',
		// },
		// {
		// 	id: 19,
		// 	cx: '-703.14819',
		// 	cy: '-1827.7968',
		// 	rx: '37.366993',
		// 	ry: '14.447782',
		// 	transform: 'matrix(-0.82193919,0.56957526,-0.71588015,-0.69822318,0,0)',
		// },
		{
			id: 20,
			cx: '585.82886',
			cy: '592.59253',
			rx: '26.011429',
			ry: '73.993675',
			transform: 'rotate(31.978485)',
		},
		{
			id: 21,
			cx: '277.00955',
			cy: '1143.1222',
			rx: '26.011429',
			ry: '73.993675',
			transform: 'rotate(-32.248156)',
		},
		// {
		// 	id: 24,
		// 	cx: '1628.5546',
		// 	cy: '1641.3691',
		// 	rx: '10.962128',
		// 	ry: '31.808733',
		// 	transform: 'matrix(0.93325934,-0.35920328,0.23061783,0.97304441,0,0)',
		// },
		// {
		// 	id: 26,
		// 	cx: '1613.7153',
		// 	cy: '2015.3538',
		// 	rx: '11.176586',
		// 	ry: '40.790325',
		// 	transform: 'matrix(0.82708125,-0.56208239,0.2933575,0.95600281,0,0)',
		// },
		// {
		// 	id: 30,
		// 	cx: '1516.196',
		// 	cy: '2039.7052',
		// 	rx: '12.622799',
		// 	ry: '45.375992',
		// 	transform: 'matrix(0.8097363,-0.58679394,0.35524863,0.93477185,0,0)',
		// },
		// {
		// 	id: 28,
		// 	cx: '1463.6384',
		// 	cy: '2046.4581',
		// 	rx: '10.601114',
		// 	ry: '37.655399',
		// 	transform: 'matrix(0.80101335,-0.59864649,0.39245463,0.91977137,0,0)',
		// },
		// {
		// 	id: 25,
		// 	cx: '1284.3043',
		// 	cy: '754.45349',
		// 	rx: '8.319129',
		// 	ry: '30.261354',
		// 	transform: 'matrix(0.97811544,0.20806293,-0.16126953,0.9869104,0,0)',
		// },
		// {
		// 	id: 27,
		// 	cx: '1364.1261',
		// 	cy: '507.35986',
		// 	rx: '10.263955',
		// 	ry: '40.570854',
		// 	transform: 'matrix(0.92082251,0.38998193,-0.29042401,0.95689806,0,0)',
		// },
		// {
		// 	id: 31,
		// 	cx: '1374.2999',
		// 	cy: '429.24796',
		// 	rx: '11.132915',
		// 	ry: '44.22229',
		// 	transform: 'matrix(0.89549851,0.4450645,-0.34402926,0.93895893,0,0)',
		// },
		// {
		// 	id: 29,
		// 	cx: '1372.2922',
		// 	cy: '359.00958',
		// 	rx: '9.6824369',
		// 	ry: '38.705338',
		// 	transform: 'matrix(0.87290048,0.4878983,-0.38290634,0.92378717,0,0)',
		// },
		// {
		// 	id: 32,
		// 	cx: '1415.0328',
		// 	cy: '26.408529',
		// 	rx: '10.283018',
		// 	ry: '32.858109',
		// 	transform: 'matrix(0.75790001,0.65237074,-0.76692443,0.64173742,0,0)',
		// },
		// {
		// 	id: 33,
		// 	cx: '606.62622',
		// 	cy: '2207.7161',
		// 	rx: '10.283018',
		// 	ry: '32.858109',
		// 	transform: 'matrix(0.52878611,-0.84875512,0.75147844,0.65975765,0,0)',
		// },
		{
			id: 34,
			cx: '518.20825',
			cy: '819.23376',
			rx: '29.294426',
			ry: '95.964493',
		},
		{
			id: 35,
			cx: '516.18787',
			cy: '594.9798',
			rx: '29.294426',
			ry: '108.08632',
		},
		{
			id: 36,
			cx: '419.21323',
			cy: '601.04071',
			rx: '41.41626',
			ry: '104.04571',
		},
		{
			id: 37,
			cx: '609.12189',
			cy: '601.04071',
			rx: '41.41626',
			ry: '104.04571',
		},
		{
			id: 38,
			cx: '420.22336',
			cy: '821.67279',
			rx: '34.345192',
			ry: '86.281807',
		},
		{
			id: 39,
			cx: '612.15234',
			cy: '821.67279',
			rx: '34.345192',
			ry: '86.281807',
		},
		{
			id: 40,
			cx: '415.1727',
			cy: '1014.1933',
			rx: '47.477169',
			ry: '86.873116',
		},
		{
			id: 41,
			cx: '617.20319',
			cy: '1014.1933',
			rx: '47.477169',
			ry: '86.873116',
		},
		{
			id: 43,
			cx: '605.08136',
			cy: '1211.173',
			rx: '35.355339',
			ry: '100.0051',
		},
		{
			id: 44,
			cx: '419.21329',
			cy: '1211.173',
			rx: '35.355339',
			ry: '100.0051',
		},
		{
			id: 47,
			cx: '565.10468',
			cy: '1443.5923',
			rx: '32.324883',
			ry: '82.832512',
			transform: 'rotate(6.0379654)',
		},
		{
			id: 48,
			cx: '474.73755',
			cy: '1545.7749',
			rx: '32.324883',
			ry: '82.832512',
			transform: 'rotate(-5.3268625)',
		},
		{
			id: 49,
			cx: '409.11176',
			cy: '1646.5486',
			rx: '19.192898',
			ry: '60.609154',
		},
		{
			id: 50,
			cx: '621.24377',
			cy: '1646.5486',
			rx: '19.192898',
			ry: '60.609154',
		},
		// {
		// 	id: 53,
		// 	cx: '842.06921',
		// 	cy: '2275.7537',
		// 	rx: '12.898176',
		// 	ry: '28.188961',
		// 	transform: 'matrix(0.93503569,-0.35455361,0.37132388,0.92850341,0,0)',
		// },
		// {
		// 	id: 54,
		// 	cx: '1959.281',
		// 	cy: '1142.1985',
		// 	rx: '12.898176',
		// 	ry: '28.188961',
		// 	transform: 'matrix(0.92353598,0.3835118,-0.36682895,0.93028841,0,0)',
		// },
		// {
		// 	id: 55,
		// 	cx: '820.98657',
		// 	cy: '2595.5244',
		// 	rx: '8.7716265',
		// 	ry: '24.172838',
		// 	transform: 'matrix(0.7329454,-0.68028747,0.40643493,0.91367973,0,0)',
		// },
		// {
		// 	id: 56,
		// 	cx: '1884.5938',
		// 	cy: '2015.9045',
		// 	rx: '8.7716265',
		// 	ry: '24.172838',
		// 	transform: 'matrix(0.9973647,-0.07255102,-0.25417169,0.96715911,0,0)',
		// },
		// {
		// 	id: 57,
		// 	cx: '903.49292',
		// 	cy: '2855.395',
		// 	rx: '7.3128157',
		// 	ry: '22.431564',
		// 	transform: 'matrix(0.50972431,-0.8603378,0.42402714,0.90564948,0,0)',
		// },
		// {
		// 	id: 58,
		// 	cx: '2194.2676',
		// 	cy: '2581.6538',
		// 	rx: '7.3128157',
		// 	ry: '22.431564',
		// 	transform: 'matrix(0.95295439,-0.30311372,-0.28603483,0.95821922,0,0)',
		// },
		// {
		// 	id: 59,
		// 	cx: '848.1449',
		// 	cy: '2780.269',
		// 	rx: '7.5073891',
		// 	ry: '22.846251',
		// 	transform: 'matrix(0.56853311,-0.82266038,0.43277862,0.90150023,0,0)',
		// },
		// {
		// 	id: 60,
		// 	cx: '2128.2808',
		// 	cy: '2335.4246',
		// 	rx: '7.5073891',
		// 	ry: '22.846251',
		// 	transform: 'matrix(0.98180114,-0.18991188,-0.32161021,0.94687215,0,0)',
		// },
		// {
		// 	id: 61,
		// 	cx: '983.80255',
		// 	cy: '2313.2607',
		// 	rx: '8.0141907',
		// 	ry: '22.41098',
		// 	transform: 'matrix(0.92816866,-0.37215984,0.34106498,0.94003972,0,0)',
		// },
		// {
		// 	id: 62,
		// 	cx: '1864.8209',
		// 	cy: '1072.3181',
		// 	rx: '6.451026',
		// 	ry: '20.537226',
		// 	transform: 'matrix(0.90458239,0.42629885,-0.33812197,0.9411023,0,0)',
		// },
		// {
		// 	id: 63,
		// 	cx: '892.85663',
		// 	cy: '2225.5769',
		// 	rx: '21.718281',
		// 	ry: '35.102798',
		// 	transform: 'rotate(-21.190107)',
		// },
		// {
		// 	id: 64,
		// 	cx: '1944.4459',
		// 	cy: '1105.4366',
		// 	rx: '21.718281',
		// 	ry: '35.102798',
		// 	transform: 'rotate(22.042808)',
		// },
		// {
		// 	id: 65,
		// 	cx: '1622.3049',
		// 	cy: '1695.036',
		// 	rx: '29.7995',
		// 	ry: '17.172594',
		// },
		// {
		// 	id: 66,
		// 	cx: '1408.6577',
		// 	cy: '1695.036',
		// 	rx: '29.7995',
		// 	ry: '17.172594',
		// },
		// {
		// 	id: 67,
		// 	cx: '1599.9419',
		// 	cy: '1543.226',
		// 	rx: '31.524561',
		// 	ry: '137.39091',
		// 	transform: 'matrix(0.99990726,-0.01361906,0.013769,0.9999052,0,0)',
		// },
		// {
		// 	id: 68,
		// 	cx: '1481.838',
		// 	cy: '1454.6552',
		// 	rx: '31.524561',
		// 	ry: '137.39091',
		// 	transform: 'matrix(0.99889153,0.04707138,-0.04692159,0.99889858,0,0)',
		// },
		// {
		// 	id: 71,
		// 	cx: '1610.6882',
		// 	cy: '1140.4622',
		// 	rx: '43.436558',
		// 	ry: '127.78429',
		// },
		// {
		// 	id: 72,
		// 	cx: '1417.7491',
		// 	cy: '1140.9673',
		// 	rx: '43.436558',
		// 	ry: '127.27921',
		// },
		// {
		// 	id: 74,
		// 	cx: '1608.4154',
		// 	cy: '950.80609',
		// 	rx: '42.17387',
		// 	ry: '49.750011',
		// },
		// {
		// 	id: 75,
		// 	cx: '1415.4763',
		// 	cy: '950.80609',
		// 	rx: '42.17387',
		// 	ry: '49.750011',
		// },
		// {
		// 	id: 78,
		// 	cx: '1514.2186',
		// 	cy: '106.57109',
		// 	rx: '52.022858',
		// 	ry: '18.182745',
		// },
		// {
		// 	id: 81,
		// 	cx: '1548.8164',
		// 	cy: '196.47467',
		// 	rx: '22.475895',
		// 	ry: '14.647212',
		// },
		// {
		// 	id: 82,
		// 	cx: '1479.1158',
		// 	cy: '196.47467',
		// 	rx: '22.475895',
		// 	ry: '14.647212',
		// },
		// {
		// 	id: 83,
		// 	cx: '1607.7289',
		// 	cy: '16.67942',
		// 	rx: '13.131983',
		// 	ry: '35.860416',
		// 	transform: 'rotate(7.1869837)',
		// },
		// {
		// 	id: 84,
		// 	cx: '1405.8334',
		// 	cy: '365.48947',
		// 	rx: '13.131983',
		// 	ry: '35.860416',
		// 	transform: 'rotate(-5.8936607)',
		// },
		// {
		// 	id: 85,
		// 	cx: '1515.2288',
		// 	cy: '272.74118',
		// 	rx: '32.829956',
		// 	ry: '17.172594',
		// },
		// {
		// 	id: 86,
		// 	cx: '1512.9561',
		// 	cy: '218.69803',
		// 	rx: '13.384521',
		// 	ry: '25.75889',
		// },
		// {
		// 	id: 89,
		// 	cx: '1513.7136',
		// 	cy: '309.86432',
		// 	rx: '22.728432',
		// 	ry: '17.930208',
		// },
		// {
		// 	id: 90,
		// 	cx: '1513.9662',
		// 	cy: '360.87698',
		// 	rx: '53.790623',
		// 	ry: '30.052038',
		// },
		// {
		// 	id: 91,
		// 	cx: '666.14856',
		// 	cy: '1561.9644',
		// 	rx: '46.130047',
		// 	ry: '88.482506',
		// 	transform: 'matrix(0.64688909,-0.7625841,0.797654,0.60311533,0,0)',
		// },
		// {
		// 	id: 92,
		// 	cx: '1223.6251',
		// 	cy: '-782.65015',
		// 	rx: '46.130047',
		// 	ry: '88.482506',
		// 	transform: 'matrix(0.62174591,0.78321901,-0.74712725,0.66468103,0,0)',
		// },
		// {
		// 	id: 93,
		// 	cx: '1512.4509',
		// 	cy: '415.93033',
		// 	rx: '76.519058',
		// 	ry: '19.445436',
		// },
		// {
		// 	id: 96,
		// 	cx: '1515.9865',
		// 	cy: '614.93036',
		// 	rx: '36.112953',
		// 	ry: '102.27795',
		// },
		// {
		// 	id: 97,
		// 	cx: '1302.7827',
		// 	cy: '1370.2698',
		// 	rx: '28.030579',
		// 	ry: '68.414696',
		// 	transform: 'matrix(0.88491878,-0.46574537,0.43806174,0.89894489,0,0)',
		// },
		// {
		// 	id: 98,
		// 	cx: '1414.8901',
		// 	cy: '187.15715',
		// 	rx: '28.030579',
		// 	ry: '68.414696',
		// 	transform: 'matrix(0.94956561,0.31356842,-0.34288278,0.9393782,0,0)',
		// },
		// {
		// 	id: 99,
		// 	cx: '1516.744',
		// 	cy: '816.96082',
		// 	rx: '50.507626',
		// 	ry: '83.085045',
		// },
		// {
		// 	id: 102,
		// 	cx: '1619.2745',
		// 	cy: '810.14233',
		// 	rx: '33.335033',
		// 	ry: '78.791901',
		// },
		// {
		// 	id: 103,
		// 	cx: '1412.6984',
		// 	cy: '810.14233',
		// 	rx: '33.335033',
		// 	ry: '78.791901',
		// },
		// {
		// 	id: 104,
		// 	cx: '1510.6831',
		// 	cy: '476.79199',
		// 	rx: '110.10663',
		// 	ry: '25.75889',
		// },
		// {
		// 	id: 105,
		// 	cx: '1418.7594',
		// 	cy: '621.24384',
		// 	rx: '42.931484',
		// 	ry: '80.812202',
		// },
		// {
		// 	id: 106,
		// 	cx: '1609.6782',
		// 	cy: '621.24384',
		// 	rx: '42.931484',
		// 	ry: '80.812202',
		// },
		{
			id: 107,
			cx: '191.65979',
			cy: '1244.9608',
			rx: '37.795055',
			ry: '13.353493',
			transform: 'matrix(0.84183713,-0.53973164,0.59213452,0.80583913,0,0)',
		},
		{
			id: 108,
			cx: '489.59393',
			cy: '732.31146',
			rx: '37.795055',
			ry: '13.353493',
			transform: 'matrix(0.88190985,0.47141809,-0.41442534,0.91008331,0,0)',
		},
		// {
		// 	id: 109,
		// 	cx: '1133.7896',
		// 	cy: '1672.1041',
		// 	rx: '28.179605',
		// 	ry: '63.483139',
		// 	transform: 'matrix(0.84109336,-0.54088997,0.5288843,0.84869394,0,0)',
		// },
		// {
		// 	id: 110,
		// 	cx: '1437.1782',
		// 	cy: '96.189102',
		// 	rx: '28.179605',
		// 	ry: '63.483139',
		// 	transform: 'matrix(0.86587308,0.50026375,-0.51251641,0.85867743,0,0)',
		// },
	],
};
